import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/*
  Generated class for the VehicleProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class VehicleProvider {
	
	pageInfo : any = {
		standardCheckList	:	[
			{
				id			:	0,
				label		:	'_GAS',
				mandatory	:	false	
			},
			{
				id			:	1,
				label		:	'_WHEELS',
				mandatory	:	false	
			},
			{
				id			:	2,
				label		:	'_SEATS',
				mandatory	:	false	
			},
			{
				id			:	3,
				label		:	'_OIL',
				mandatory	:	false	
			},
			{
				id			:	4,
				label		:	'_TRUNK',
				mandatory	:	false	
			},
			{
				id			:	6,
				label		:	'_AUTONOMY',
				mandatory	:	false	
			},
			{
				id			:	7,
				label		:	'_GPS',
				mandatory	:	false	
			},
			{
				id			:	8,
				label		:	'_DIRT',
				mandatory	:	false	
			},
		]
	}
	constructor() {
  	}

	/**
	 * Mock a list of items to check the vehicle
	 * @param vehicleId 
	 */
	getCheckinList(vehicleId){
		return this.pageInfo.standardCheckList;
	} 
	sendCheckListVehicle(report){
		// send report to the server
		return;
	}
	/**
	 * Return the map position where the vehicle starts and end
	 */
	getBase(){
		return {	start	:	{	lat	:	39.5590623,
									lng :	2.5861329	
								},
					end		:	{	lat	:	39.5590623,
									lng :	2.5861329	
								},
				}	
	} 
}
