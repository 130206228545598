import { MomentProvider } from 'src/app/providers/moment/moment';
import { CommonsProvider } from 'src/app/providers/commons/commons';
import { Component, Input, Output, EventEmitter } from '@angular/core';

/**
 * Generated class for the AppInvoiceCardComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'app-invoice-card',
  templateUrl: 'app-invoice-card.html'
})
export class AppInvoiceCardComponent {

	
	@Input() 	invoice : any;
	@Output()	emitter		=	new EventEmitter();

	constructor(private commons 		: CommonsProvider,
				private momentProvider	:	MomentProvider) {
	}

	clickInvoice(){
		this.emitter.emit({type : 'invoice', value : this.invoice});
	}
	getInfoStatus(status){	return this.commons.getInfoStatusElements(status);	}
	computeDay(day)		{	if(!day){return '-'}; return this.momentProvider.formatDate(day,'YYYY-MM-DD');		}
}
