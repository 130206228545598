import { 	Platform 			} from '@ionic/angular';
import { 	Component, 
			Input, 
			OnInit, 
			ViewChild, 
			EventEmitter, 
			Output 				} from '@angular/core';
import { AgmCoreModule, AgmMap 	} from '@agm/core';
import { LocatorProvider 		} from 'src/app/providers/locator/locator';

declare var google: any;

/**
 * Generated class for the MapComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector		: 'app-map',
  templateUrl	: 'map.html',
  styles		: ['map.scss'],
})
export class MapComponent implements OnInit{

	@ViewChild('map', null)	map		: AgmMap;
	@Input() 	height    			: any;
  	@Input() 	mapConfig    		: any;
  	@Output() 	emiterOption = new EventEmitter<any>();

	mapInstance				:	any;
	provideRouteAlternatives	:	boolean	= true;
	waypoints 				: 	any 	= []
	renderOptions 			: 	any 	= {draggable	: false}
	transitOptions			:	any = {
		departureTime		: new Date(),
		modes				: ['BUS','SUBWAY'],
		routingPreference	: 'FEWER_TRANSFERS'
	};

	pageInfo  				: any = {
		trafficLayer		: {},
		markers 			: [],
		listMarkers			: [],
		listDirections		: [],
		listPolylines		: [],
		displayDirection	:	false,
		route				: {
			origin		:	{
					coords	:	{}
			},
			destination	:	{
					coords	:	{}
			}
		},
		icons				:	{
			transport	:	{
				url: 'assets/imgs/transportMarker.png',
				scaledSize: {
					width: 32,
					height: 32
				}
			},
			lodging	:	{
				url: 'assets/imgs/hotel_set_1.png',
				scaledSize: {
					width: 32,
					height: 32
				}
			},
			airport : 	{
				url	:	'assets/imgs/airport_marker.png',
				scaledSize	:	{
					width:32,
					height:32
				}
			},
			default	:	{
				url	:	'https://cdn3.iconfinder.com/data/icons/gradient-general-pack/512/location-01-512.png',
				scaledSize	: {
					width	: 32,
					height	: 32
				}
			}
		}
	}

  	myLodgig					: any =	{
		url: 'assets/img/hotel_set_1.png',
		scaledSize: {
			width: 32,
			height: 32
		}
  	}

	// http://os-ekvaternika-rakovica.skole.hr/upload/os-ekvaternika-rakovica/images/multistatic/80/Image/source.gif
	myLocation  = {
		url : 'https://cdn3.iconfinder.com/data/icons/gradient-general-pack/512/location-01-512.png',
		scaledSize  : {
		width   : 32,
		height  :  32,
		}
	}

  	constructor(	
		private locator 	: LocatorProvider,
		private platform 	: Platform
	){}

	ngOnInit()			{
		this.map.mapReady.subscribe(map => {
			this.mapInstance = map;
        });
	}
	onMapReady($event)	{	this.locator.startTracking();  	}
	  
	getViewChildMap()	{	return this.map;	}
	
	displayTrafficLayer(){
		this.platform.ready().then(()=>{
			try{
				this.pageInfo.trafficLayer = new google.maps.TrafficLayer(); // to display the traffic layer colors 
				this.pageInfo.trafficLayer.setMap(this.mapInstance);
			}catch(e){
				console.warn('does not found google once is load for first time');
				return;
			}
		});	
	}
	removeTrafficLayer()	{	this.pageInfo.trafficLayer.setMap(null);	}

	moveMapPosition(coords)	{
		this.mapConfig.location.lat 	=	coords.lat;
		this.mapConfig.location.lng		=	coords.lng;	
	}

  	addTypeMarker(infoMarker){
		let findMarker	=	this.pageInfo.listMarkers.findIndex(el => el.id == infoMarker.id);
		if(findMarker > -1){
			this.pageInfo.listMarkers[findMarker]	=	infoMarker;
		}else{
			this.pageInfo.listMarkers.push(infoMarker);
		}
		console.log('list markers', this.pageInfo.listMarkers);
  	}

  	removeTypeMarker(markerId){
		let findMarker	=	this.pageInfo.listMarkers.findIndex(el => el.id == markerId);
		if(findMarker > -1){
			this.pageInfo.listMarkers.splice(findMarker,1);
	  	}else{
			console.error('COULD NOT FOUND MARKER');
	  	}
	}

	restartEntity(entity){
		switch(entity){
			default	:	return;
			case 'marker'	:	this.pageInfo.listMarkers = [];
		}
	}

	addDirection(data){
		this.pageInfo.listDirections.push(data);
		console.log(this.pageInfo.listDirections);
	}
	
	  
  	getTypeMarker(id)		{	return this.pageInfo.listMarkers.find(el => el.id == id);  }

  	infoRouteObtained($event){
		console.log('response',$event);
		if( $event == null || $event.status != 'OK') return;
		this.emiterOption.emit($event);
	}
	onChangeRoute($event){
		console.log($event);
	}
	getIconUrl(icon){		return this.pageInfo.icons[icon] || this.pageInfo.icons.default;	}

	addPolyline(polyline){	this.pageInfo.listPolylines.push(polyline);	}
}
