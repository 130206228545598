import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector		: 'app-route-item',
  templateUrl	: 'route-item.html',
  styleUrls		: [ './route-item.scss' ]
})
export class RouteItemComponent implements OnInit{

	@Input()	item			:	any;
	@Input()	index			:	any;
	@Input()	random			:	boolean = false;
	@Input()	status			:	any;

	@Input() 	pageInfo		:	any; //HACK
	@Output()	itemSelected	=	new EventEmitter();
	
	constructor() {}
	
	ngOnInit(){
		// this.pageInfo.startHour = this.getRandomHour(this.index || 0);
		// if(this.index > 0){
		// 	this.random = true;
		// 	this.pageInfo.type	=	['SHARED','PRIVATE'][Math.floor(Math.random() * ['SHARED','PRIVATE'].length)];
		// 	this.pageInfo.pax	=	[2,4,6,8,12,15,18][Math.floor(Math.random() * [2,4,6,8,12,15,18].length)];
		// 	this.pageInfo.to	=	['NORTE','MIGJORN','CALVIÀ','CALAS MALLORCA'][Math.floor(Math.random() * ['NORTE','MIGJORN','CALVIÀ','CALAS MALLORCA'].length)];
		// 	this.pageInfo.plate	=	['3656KSH', '5985BHY','7865DRS','9873KKW'][Math.floor(Math.random() * ['3656KSH', '5985BHY','7865DRS','9873KKW'].length)];
			
		// }
	}

	getRandomHour(i){
		let randomMinutes	=	[60,65,66,67,68,69,70,71,72,76,53,72,80];
		return this.formatStartTime((i + i) * randomMinutes[i])
	}

	formatStartTime(addTime?){
		let now = new Date();
		let test = moment(now).add('minutes',addTime || 40);
		return test.format('HH:mm');
	}
	
	onSelectItem(){this.itemSelected.emit(this.item);}
	
	getClassByStatus(){
		if		(!this.status)	{ return 'crimson'};
		switch	(this.status)	{
			case 'past'		:	return 'lightgray';
			case 'active'	:	return '#0a8f83';
			case 'pending'	:	return 'crimson';
		}
	}
}
