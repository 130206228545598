import { Component, Input, Output, EventEmitter } from '@angular/core';
import { treeItems } from 'src/app/providers/typed-chat/data/items';

/**
 * Generated class for the CheckListComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'app-check-list',
  templateUrl: 'check-list.html'
})
export class CheckListComponent {

	@Input()	checkList	:	any = [];
	@Output()	emitter		=	new EventEmitter();

	constructor() {
	}

	toggleAllItems($event){
		this.checkList.forEach(el => el.checked = $event.checked);
	}

	canFinish()			{	
		return true;
		return (this.checkList || []).every(el => el.checked == true);	
	}
	finishCheckList()	{	this.emitter.emit(this.checkList);}
}
