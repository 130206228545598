import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  	constructor(
    	private afs : AngularFirestore
  	){}

  	async createUser(user)    		{	await Promise.resolve(this.afs.collection('users').doc(user.id).set(user));	}
  	subscribeToRef(ref)				{	return this.afs.doc(ref).valueChanges();															}
	async getItemByRef(ref)			{	return await this.afs.doc(ref).ref.get()															}
	async updateItemByRef(ref,item)	{	return this.afs.doc(ref).update(item)																}
	async updateUserById(user)		{	await Promise.resolve(this.afs.collection('users').doc(user.id).update(user));						}
	async getUserById(id)			{	return await Promise.resolve(this.afs.collection('users').doc(String(id).toString()).ref.get());	}
	async getDataFromRef(ref)		{	let call	=	await this.afs.doc(ref).ref.get();
										return call.data();
									}
	async getUserInfo(id)			{	let user = this.getUserById(id); return user?(await user).data():{};	}
}
