import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector		: 'app-group-buttons',
  templateUrl	: 'app-group-buttons.html',
  styleUrls   	: ['./app-group-buttons.scss'],
})
export class AppGroupButtonsComponent implements OnInit{

  @Input() groupButtons  	: any;
  @Input() background     	: any 	= 	'white';
  @Input() color          	: any 	= 	'rgba(0,0,0,0.6)'
  @Output()	emitter					=	new EventEmitter();

	constructor() {
    	console.log('Hello AppGroupButtonsComponent Component');
  	}

  	executeButtonActions(action){
    	this.emitter.emit(action);
  	}

	ngOnInit(): void {}

}
