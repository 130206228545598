import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FirebaseControllerProvider } from '../firebase-controller/firebase-controller';
import { TypedChatProvider } from '../typed-chat/typed-chat';

/*
  Generated class for the FirebaseChatProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class FirebaseChatProvider {

	constructor(	private afs 				: 	AngularFirestore,
					private firebaseControler	:	FirebaseControllerProvider,
					private typedChatCtrl		:	TypedChatProvider,
	  			) {
		console.log('Hello FirebaseChatProvider Provider');
	}

	async testTypedChat(data)	{	
		let currentChat	=	await this.afs.collection('test').doc('typedChat').ref.get();
		console.log(currentChat.data(),data);
		if(currentChat.data().typedChat){
			let items = currentChat.data().typedChat;
			items.push(data);
			await this.afs.collection('test').doc('typedChat').update({typedChat	:	items});
		}else{
			await this.afs.collection('test').doc('typedChat').update({typedChat : [data]});
		}
	}
	/**
	 * 
	 * @param data 
	 * @param customer 
	 */
	async updateCustomerTypedChat(data,refRoute,customer){
		console.log('updateing chaaat typed');
		let currentRoute			=	await this.firebaseControler.getDataFromRef(refRoute);
		currentRoute.listTypedChats	=	currentRoute.listTypedChats || [];
		let findCustomerOnTyped		=	currentRoute.listTypedChats.findIndex(el => el.reference == customer.reference);
		if(findCustomerOnTyped > -1){
			currentRoute.listTypedChats[findCustomerOnTyped].currentListMessages.push(data);
			
			if(data.items.length == 0){ //meaning it is a leaf node
				currentRoute.listTypedChats[findCustomerOnTyped].savedListMessages =	currentRoute.listTypedChats[findCustomerOnTyped].savedListMessages || [];
				//store them savely
				currentRoute.listTypedChats[findCustomerOnTyped].savedListMessages.push({id 	: currentRoute.listTypedChats[findCustomerOnTyped].savedListMessages.length,
																						chat	: currentRoute.listTypedChats[findCustomerOnTyped].currentListMessages	}) 
			}
		}else{
			currentRoute.listTypedChats.push({
				reference			:	customer.reference,
				currentListMessages	:	[data],
				// restart			:	false
			})
		}
		console.log('On Update test chat...',currentRoute)
		await this.firebaseControler.updateItemByRef(refRoute,{listTypedChats : currentRoute.listTypedChats});
	}

	async updateLevelCustomerTypedChat(id, level, refRoute, bookingRef){
		let currentRoute		=	await this.firebaseControler.getDataFromRef(refRoute);
		let foundCustomerChat	=	currentRoute.listTypedChats.findIndex(el => el.reference == bookingRef);
		if(foundCustomerChat == -1){ return;}
		let foundLevel	=	currentRoute.listTypedChats[foundCustomerChat].currentListMessages.findIndex(el => el.id == id);
		currentRoute.listTypedChats[foundCustomerChat].currentListMessages[foundLevel]	=	level;
		await this.firebaseControler.updateItemByRef(refRoute,{listTypedChats : currentRoute.listTypedChats});
	}

	async cancelLevelChat(refRoute,bookingRef){
		let currentRoute		=	await this.firebaseControler.getDataFromRef(refRoute);
		let foundCustomerChat	=	currentRoute.listTypedChats.findIndex(el => el.reference == bookingRef);
		if(foundCustomerChat == -1){ return;}
		
		if(currentRoute.listTypedChats[foundCustomerChat].currentListMessages[currentRoute.listTypedChats[foundCustomerChat].currentListMessages.length-1].id == 0){
			currentRoute.listTypedChats.splice(foundCustomerChat,1);
		}else{
			//remove last message
			currentRoute.listTypedChats[foundCustomerChat].currentListMessages.splice(currentRoute.listTypedChats[foundCustomerChat].currentListMessages.length-1, 1);
				
			//set to default the last list messages
			let restartOptionsObject	=	currentRoute.listTypedChats[foundCustomerChat].currentListMessages[currentRoute.listTypedChats[foundCustomerChat].currentListMessages.length -1];
			restartOptionsObject.answered = false
			restartOptionsObject.items.forEach(item => delete item.options.class);
		}	
		await this.firebaseControler.updateItemByRef(refRoute,{listTypedChats : currentRoute.listTypedChats});
	}

	async restartTypedChat(refRoute, bookingRef){
		let currentRoute		=	await this.firebaseControler.getDataFromRef(refRoute);
		let foundCustomerChat	=	currentRoute.listTypedChats.findIndex(el => el.reference == bookingRef);
		if(foundCustomerChat == -1){ return;}
		currentRoute.listTypedChats[foundCustomerChat].currentListMessages = [];
		// currentRoute.listTypedChats.splice(foundCustomerChat,1);
		await this.firebaseControler.updateItemByRef(refRoute,{listTypedChats : currentRoute.listTypedChats});

	}
	async updateLevelTypedChat(id,level){
		let currentChat	=	await this.afs.collection('test').doc('typedChat').ref.get();
		let levels		=	currentChat.data().typedChat;
		let indexLevel	=	levels.findIndex(el => el.id == id);
		levels[indexLevel]	=	level;	
		await this.afs.collection('test').doc('typedChat').update({typedChat : levels});
	}
	async updateTypedChat(messages){
		await this.afs.collection('test').doc('typedChat').update({typedChat : messages});
	}
	subscribeTypedChat()		{	return this.afs.collection('test').doc('typedChat').valueChanges();					}


}
