import { Component, Input, Output, EventEmitter } from '@angular/core';

/**
 * Generated class for the LodgingItemComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector		: 'app-lodging-item',
  templateUrl	: 'lodging-item.html'
})
export class LodgingItemComponent {

	@Input() 	item			:	any = {};
	@Input()	slidingOptions	:	any;
	@Output()	emiterOption	=	new EventEmitter();

	constructor() {
  	}
	
	mountPax(){
		if(!this.item || !this.item.sumPax){ return [];}
		return Object.keys(this.item.sumPax).map(el =>{
			return {
				type 	: 	el,
				qty		:	this.item.sumPax[el],
				img		:	this.getTypeIcon(el)
			}
		})
	}
	
	getTypeIcon(type){	
		return 'assets/imgs/'+type+'.png';	
	}

	onSelectItem(){this.emiterOption.emit(this.item);}
	selectSlidingOption(value)	{	
		this.emiterOption.emit({type	:	'slide',
								data	: {	item	:	this.item,	value	:	value	}					
								});
	}
}
