import { Storage 					} from '@ionic/storage';
import { Component, OnInit 			} from '@angular/core';
import { StatusBar 					} from '@ionic-native/status-bar/ngx';
import { SplashScreen  				} from '@ionic-native/splash-screen/ngx';
import { TranslateService 			} from '@ngx-translate/core';
import { MenuController, 
		 NavController, 
		 Platform 					} from '@ionic/angular';
import { FirebaseControllerProvider } from './providers/firebase-controller/firebase-controller';
import { LocatorProvider 			} from './providers/locator/locator';
import { CommonsProvider 			} from './providers/commons/commons';
import { AngularFireAuth 			} from '@angular/fire/auth';
import { menuItems 					} from './menuItems';
import { UserService 				} from './services/user/user.service';

@Component({
  	selector	: 'app-root',
	templateUrl	: 'app.component.html',
	styleUrls	: ['app.component.scss']
})
export class AppComponent implements OnInit{

	lang	 	: any = "es";
  	rootPage	: any;
	pages    	: any[];
	pageInfo	: any;
	userInfo	: any;

	constructor(	
		public 	platform		: Platform, 
		public 	statusBar		: StatusBar,
		public 	splashScreen	: SplashScreen,
		public  firebaseCtrl	: FirebaseControllerProvider,
		public 	translate 		: TranslateService, 
		public 	locator 		: LocatorProvider,
		private storage			: Storage,
		public 	commons			: CommonsProvider,
		private afAuth        	: AngularFireAuth,
		public  menuCtrl     	: MenuController,
		private navCtrl			: NavController,
		private userCtrl		: UserService
	) {
    	platform.ready().then(async () => {
			//this.statusBar.styleDefault();
			this.statusBar.backgroundColorByHexString("#303030");
			this.statusBar.show();				
			this.splashScreen.hide();
			//let splash = modalCtrl.create(SplashPage);
			//splash.present();		
			menuCtrl.enable(false, 'right');
			});
  	}

	async ngOnInit()		{ 	
		await this.initializeApp();
	}

	async initializeApp() 	{	
		try{
			this.initPlatform();
			this.initLocator();
			this.initMenu();
			this.commons.simulation 	= true;

			await this.initUser();

		} catch(e){
			await Promise.resolve(this.storage.set('user',{}));
			this.rootPage = 'login';
		}
	}

	initPlatform(){
		// Init platform services
		this.lang = "es";
		this.translate.setDefaultLang(this.lang);
		this.translate.addLangs(['es', 'en']);
		this.translate.reloadLang(this.lang);
		this.commons.initTranslate("es");	
	}

	/**
	 * init GPS
	 */
	initLocator(){
		this.locator.init();
		this.locator.startTracking();
	}

	async initMenu(){
		// Create menu
		this.pages = await Promise.all(menuItems.map(async item=>{
			// item.title = await this.commons.getTranslate(item.title);
			return item;
		}));
	}

	async initUser()		{
		// this.afAuth.authState.subscribe(async auth => {
		// 	this.loginWatchdog(auth);
		// });
		this.userInfo = {};
		this.initDriver();
	}

	/**
	 * get user Info from backend and move to rootPage
	 * 
 	 * @param auth 
	 */
	 async loginWatchdog(auth){			
		// if(!auth){	this.initCustomer(); return;	}
		this.userInfo					= await this.userCtrl.getUserInfo(auth.uid);
		this.initDriver();
	 }

	 async initDriver(){
		// if(this.userInfo){
		// 	await Promise.resolve(this.storage.set('user', this.userInfo));
		// }
		console.log('[lodginWatchDog] UserInfo', this.userInfo);
		
		// this.userInfo.control 			= 	(Object.keys(this.userInfo).length === 0 && this.userInfo.constructor === Object) ? {} : this.userInfo.control;
		this.userInfo.control			=	this.userInfo.control			|| {};
		this.userInfo.control.isLogged	=	this.userInfo.control.isLogged	|| false;
		this.userInfo.control.hasCurrent=	this.userInfo.control.hasCurrent|| false;
		this.userInfo.control.status	=	this.userInfo.control.status	|| "NOT_LOGGED";

		this.userInfo.driverId			=	"1gVcabY4dxMzMAU6MM3d";
		console.log("CONTROL",this.userInfo.control);

		// let driverInfo	=	await Promise.resolve(this.storage.get('driverInfo')) || {};
		this.userInfo.driver	= await this.firebaseCtrl.getDriverInfo(this.userInfo.driverId);
		
		this.rootPage			= this.userInfo.control.isLogged ? 'my-services' : 'login';
		this.lang 				= this.userInfo.lang || "es";
		
		// await this.checkSteps();
		// this.rootPage	=	this.commons.getStatusPage({info : this.userInfo}); //SHOULD DO METHOD TO CHECK THE POSITION AND SETROOT
		// switch(this.rootPage){
		// 	case "login"	: break;
		// 	default			: this.initLocatorInfo(); break;
		// }
		// await Promise.resolve(this.storage.set('user', this.userInfo));

		this.commons.userInfo	= this.userInfo;
		console.log("MOVE TO",this.rootPage);
		this.next();
	}
  
	/**
	 * move to rootPage
	 * 
	 */
	next(){
		switch(this.rootPage){
			case "login":	this.menuCtrl.enable(false); break;			
		}
		this.navCtrl.navigateRoot(this.rootPage);	
	}

	async openPage(page) 	{	switch(page.id){
			case 'null'		: 	return;

			case 'restart'	: 	if(this.userInfo.id){
									// await this.tripCtrl.restartTest(this.userInfo);
									// await Promise.resolve(this.storage.set('user',{}));
									// this.afAuth.signOut();
									// return this.navCtrl.navigateRoot('login');
								} else {
									console.log("[app] Error on restart");
									console.log(this.userInfo);
								}
								break;

			case 'logout'	:	await Promise.resolve(this.storage.set('user', {}));
								await this.afAuth.signOut();
								// this.navCtrl.navigateRoot('login');
								break;
			default			:	// this.navCtrl.navigateForward(page.url);
								break
		}
	}
}
