import { Component, Input, Output, EventEmitter } from '@angular/core';

/**
 * Generated class for the InfoPanelComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'app-info-panel',
  templateUrl: 'info-panel.html'
})
export class InfoPanelComponent {

	@Input() 	background		:	any;
	@Input() 	title			:	any;
	@Input() 	content			:	any;
	@Input()	type			:	any;
	@Input()	action			:	any;
	@Input()	titleAction		:	any;
	@Output()	emiterOption	=	new EventEmitter();
	constructor() {
	}
	  
	clicked()	{	this.emiterOption.emit(this.type);	}
}
