import { ValidatorProvider 								} 	from 'src/app/providers/validator/validator';
export const statusInfo =   [
    {
        step        : 0,
        value       : 'unknown',
        label       : '_UNKNOWN',
        description : '_DESCRIPTION_UNKNOWN',
        color       : 'white',
        background  : '#673AB7',
        validator : () => { return ValidatorProvider.notVerify(); },
    },
    {
        step        : 1,
        value       : 'pending_revision', 
        label       : '_PENDING_REVISION',
        description : '_DESCRIPTION_PENDING_REVISION',
        color       : 'white',
        background  : '#005a96',
        validator : () => { return ValidatorProvider.notVerify(); },
    },
    {
        step        : 2,
        value       : 'pending_edition',
        label       : '_PENDING_EDITION',
        description : '_DESCRIPTION_PENDING_EDITION',
        color       : 'white',
        background  : '#ef7049',
        validator : () => { return ValidatorProvider.notVerify(); },
    },
    {
        step        : 3,
        value       : 'revision',
        label       : '_REVISION',
        description : '_REVISION',
        color       : 'white',
        background  : '#03A9F4',
        validator : () => { return ValidatorProvider.notVerify(); },
        
    },
    {
        step        : 4,
        value       : 'rejected',
        label       : '_REJECTED',
        description : '_DESCRIPTION_REJECTED',
        color       : 'white',
        background  : '#dc0e0e',
        validator : () => { return ValidatorProvider.notVerify(); },
    },
    
    {
        step        : 5,
        value       : 'accepted',
        label       : '_ACCEPTED',
        description : '_DESCRIPTION_ACCEPTED',
        color       : 'white',
        background  : '#009688',
        validator : () => { return ValidatorProvider.notVerify(); },
    },
    {
        step        : 6,
        value       : 'pending_payment',
        label       : '_PENDING_PAYMENT',
        description : '_DESCRIPTION_PENDING_PAYMENT',
        color       : 'white',
        background  : '#2dae5f',
        validator : () => { return ValidatorProvider.notVerify(); },
    },
    {
        step        : 7,
        value       : 'paid',
        label       : '_PAID',
        description : '_DESCRIPTION_PAID',
        color       : 'white',
        background  : 'green',
        validator : () => { return ValidatorProvider.notVerify(); },
    }
]
