import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range'; 

const moment = extendMoment(Moment);


/*
  Generated class for the MomentProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class MomentProvider {

	moment:any;

	constructor(public http: HttpClient, private translate : TranslateService) {
		console.log('Hello MomentProvider Provider');
		this.moment = Moment;
	}

	getDate($date,$format="YYYY-MM-DD"){
		return moment($date).format($format);
	}

	getToday($format="YYYY-MM-DD"){
		return moment().format($format);
	}
	/**
	 * Create an array of weeks from the start of week between two dates. Each week will contain an array of 7 days
	 * @param from Date
	 * @param until Date
	 */
	getRangeDates(from?,until?){
		let dateFrom		=	from 	? moment(from) 	: moment(moment(new Date()).subtract(1,'years').startOf('isoWeek').calendar()); // get the monday day from one year ago
		let dateUntil		=	until 	? moment(until) : moment(moment(new Date()).add(1,'years').endOf('isoWeek').calendar());		// get the monday day from one year later
		let rangeWeeks		=	Array.from(moment.range(dateFrom, dateUntil).by('week'));
		let range			=	rangeWeeks.map	(	(date, i, rangeWeeks)	=>	
										{ 
											let start 	= moment(rangeWeeks[i])
											let end  	= moment(rangeWeeks[i+1]).subtract(1,'days')
											return { 	
												start	: start.format('YYYY-MM-DD'), 
												end 	: end.format('YYYY-MM-DD'),
												label	: this.mountLabelWeek(start,end),
												newMonth: this.isDifferentMonth(start,end),
												idMonth : this.getInfo(end,'month'),
												days	: Array.from(moment.range(start,end).by('day')).map(el => { return {day : el }})
											}
										}
								)
		return range;
	}

	mountLabelWeek(start,end){
		try{
			const numberStart 		= moment(start).format('DD');
			const numberEnd			= moment(end).format('DD');
			const labelMonthStart	= String(moment(start).locale(this.translate.getDefaultLang()).format('MMM')).toUpperCase() + '.';
			const labelYearEnd		= moment(end).locale(this.translate.getDefaultLang()).format('YYYY')
			
			if(moment(start).month() == moment(end).month()){
				return numberStart + '-' + numberEnd + ' ' + String(labelMonthStart).toUpperCase() + ' ' + labelYearEnd;
			}else{
				const labelMonthEnd	= String(moment(end).locale(this.translate.getDefaultLang()).format('MMM')).toUpperCase() + '.';;
				return numberStart + ' ' + labelMonthStart +' - '+ numberEnd + ' ' + labelMonthEnd + ' ' + labelYearEnd;
			}
		}catch(e){
			return null;
		}
	}

	isDifferentMonth(date1, date2){
		try{
			if(moment(date1).format('MMM') == moment(date2).format('MMM') && moment(date1).date() != 1){
				return false;
			}else{
				return moment(date2).locale(this.translate.getDefaultLang()).format('MMMM') + ' '+ moment(date2).locale(this.translate.getDefaultLang()).format('YYYY');
			}
		}catch(e){
			return false;
		}
	}

	checkStartWeekDates(date1, date2){
		if(!date1 || !date2){ return false;}
		return moment(date1).startOf('isoWeek').isSame(moment(date2).startOf('isoWeek'));
	}

	compareDates(date1,date2){
		if(!date1 || !date2){return false;}
		return moment(moment(date1).format('YYYY-MM-DD')).isSame(moment(moment(date2).format('YYYY-MM-DD')));
	}


	/**
	 * Check if two dates accomplish a condition
	 * @param time1 
	 * @param time2 
	 * @param type before, same, after
	 */
	checkDates(time1,time2,type){
		if(!type){return false;}
		switch(type){
			case 'before'	:	return moment(time1).isBefore(moment(time2));
			case 'same'		:	return moment(time1).isSame(moment(time2));
			case 'after'	:	return moment(time1).isAfter(moment(time2));
			default			:	return false;
		}
	}
	getShortLabelDay(date){
		try{
			return {
				date	:	moment(date).date(),
				label	:	moment(date).locale(this.translate.getDefaultLang()).format('ddd')
			}
		}catch(e){
			return null;
		}
	}

	formatDate(date, format?){
		if(!date){ return null};
		if(date == 'today'		)	{	date = new Date()	}
		
		date = this.formatTimestamp(date);
		if(format == 'toDate'	)	{	return	moment(date).toDate();}
		else{							return format ?	moment(date).format(format) : moment(date); }
	}
	/**
	 * Convert the object into timestamp
	 * @param element Object from firebase, can have seconds property or timestamp
	 */
	formatTimestamp(element){
		if(!element){return;}
		return element['seconds'] ? element['seconds']*1000 : element;
	}

	/**
	 * Return the closest elementRefId from the actual scrolled position
	 * @param $event scroll event from ionScroll output
	 */
	getDateByScrolling($event){ 
		let listVisibleElements :	any = Array.from($event.scrollElement.children).filter((el : any) => el.id != '').filter((el : any)=>{
			let distance	=	$event.scrollTop - el.offsetTop
			return distance > 0
		});
		if(listVisibleElements[listVisibleElements.length-1]){
			let currentDate	=	this.computeScrolledCurrentDate(listVisibleElements);
			return {
				success			:	true,
				currentMonth	:	this.formatDate(currentDate,'MMM YYYY'),
				currentDate		:	this.formatDate(currentDate,'toDate')
			}
		}else{
			return { success : false}
		}	
	}

	isValidDate(date){
		return moment(date).isValid();
	}
	computeScrolledCurrentDate(listElements : any[]){
		let last	=	listElements[listElements.length-1].id;
		if(last == 'today'){ return new Date();}
		if(this.isValidDate(last)){ return last};
		let findClosestValid	=	listElements.reverse().find(element => this.isValidDate(element.id));
		return findClosestValid ? findClosestValid.id : null;
	}

	getInfo(date, info){	return moment(date)[info]()	}

	getHumanizedDiffTime(initTime, endTime){
		return moment.duration(moment(this.formatTimestamp(initTime)).diff(moment(this.formatTimestamp(endTime)))).locale(this.translate.getDefaultLang()).humanize()
	}
	getDiffTime(initTime,endTime,value){
		return moment.duration(moment(this.formatTimestamp(initTime)).diff(moment(this.formatTimestamp(endTime))))['asDays']()
	}
	
	/**
	 * Add unit time (hours, minutes, seconds, etc) to moment item
	 * @param moment 
	 * @param qty 
	 * @param type 
	 */
	addUnits(moment,qty,type){	return moment.add(qty,type);	}
}
