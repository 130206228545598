export const environment = {
	production		: false,
	firebaseConfig	: {
	    apiKey: "AIzaSyDXGo6MTulw5-o7s-4G3Wioa5KgnY4cRyM",
		authDomain: "tourinia.firebaseapp.com",
		databaseURL: "https://tourinia.firebaseio.com",
		projectId: "tourinia",
		storageBucket: "tourinia.appspot.com",
		messagingSenderId: "29132584927",
		appId: "1:29132584927:web:f81909c8b14cc88348f66a",
		measurementId: "G-NJKQXK3P2L"
	},
	firebase		: {
	    apiKey: "AIzaSyDXGo6MTulw5-o7s-4G3Wioa5KgnY4cRyM",
		authDomain: "tourinia.firebaseapp.com",
		databaseURL: "https://tourinia.firebaseio.com",
		projectId: "tourinia",
		storageBucket: "tourinia.appspot.com",
		messagingSenderId: "29132584927",
		appId: "1:29132584927:web:f81909c8b14cc88348f66a",
		measurementId: "G-NJKQXK3P2L"
	}
};