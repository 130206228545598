export const menuItems = [
    {	title		: '_HOME',	
        id			: 'home',
        icon 		: 'home',
        url			: '/main',				
        clicked 	: false, 
        subPages 	: [] 	
    },
    {	title		: '_CHAT',
        id			: 'chat',	
        icon 		: 'chatbubbles', 				
        clicked 	: false, 
        url			: '/chat-master',
        subPages 	: [] 	
    },

    {	title		: '_ISSUES',
        id			: 'issues', 		
        icon 		: 'alert-circle', 	
        _color		: 'faGreen', 
        clicked 	: false, 
        url			: '/issues',
        subPages 	: [] 	
    },
    {	title		: '_PREFERENCES',
        id			: 'settings', 			
        icon 		: 'cog', 					
        _color		: 'faGreen', 
        clicked 	: false, 
        url			: '/settings',
        subPages 	: [] 	
    },
    {	title		: '_CHECKOUT',	
        id			: 'checkout',
        icon 		: 'log-out',	 			
        _icon 		: 'calendar-check',
        _color		: 'faGreen',
        clicked 	: false,
        url			: '/checkout',
        subPages 	: []	
    },
    {	title		: '_LOG_OUT',	
        id			: 'logout',
        icon 		: 'log-out',	 			
        _color		: 'faGreen', 
        clicked 	: false, 
        url			: '/login',
        subPages 	: []	
    },

    {
        title		: '_RESTART',
        id			: 'restart',
        _color		: 'faGreen', 
        clicked 	: false, 
        url			: '/login',
        subPages 	: []	
    }
];