import { FirebaseControllerProvider } from './../firebase-controller/firebase-controller';
import { CommonsProvider } from './../commons/commons';
import { SlidingProvider } from './../sliding/sliding';
import { LodgingProvider } from './../lodging/lodging';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/*
  Generated class for the RouteProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class RouteProvider {

	pageInfo	:	any	=	{}
	constructor(private lodgingCtrl	:	LodgingProvider,
				private slidingCtrl	:	SlidingProvider,
				private firebaseCtrl:	FirebaseControllerProvider,
				private commons		:	CommonsProvider) {
		this.pageInfo	=	{
			airportPoints : [
			{
				lat	:	39.547814,
				lng	:	2.730156
			},
			{
				lat : 39.5481293,
				lng : 2.7303737
			},
			{
				lat :	39.5472439,
				lng :	2.7305961
			},
			{
				lat :	39.546626,
				lng	:	2.7307707
			},
			{
				lat : 39.5467486,
				lng	: 2.7308681
			},
			{
				lat : 39.5479632,
				lng : 2.7299863
			}
							],
			imgStatusCustomer	:	{
				'unknown'					:	'assets/imgs/unknown.png',
				'_ARRIVAL_GATE'				:	'assets/imgs/arrivalFinger.png',
				'_ARRIVAL_FINGER'			:	'assets/imgs/arrivalFinger.png',
				'_ARRIVAL_MEETING'			:	'assets/imgs/arrivalMeeting.png',
				'_ARRIVAL_BELT'				:	'assets/imgs/arrivalBelt.png',
				'_ARRIVAL_OFFICE'			:	'assets/imgs/arrivalMeeting.png',
				'_WAITING_POINT_GROUP'		:	'assets/imgs/waitingPointGroup.jpg',
				'_ARRIVAL_WAITING_TRANSPORT':	'assets/imgs/waitingTransport.jpg',
				'arrival_checked'			:	'assets/imgs/checked.png',
				'arrival_delivered'			:	'assets/imgs/arrivalLodging.png'
			},
			imgLocation			:	{
				'enabled'	:	'assets/imgs/sharingLocation.jpg',
				'disabled'	:	'assets/imgs/locationDisabled.png'
			},
			imgs	:	{
				timer			:	'assets/imgs/timer.png',
				lodging			:	'assets/imgs/hotel_set_1.png',
				travellingTime	:	'assets/imgs/travellingTime.png'	
			}
		}		
	}

	/**
	 * 
	 * @param data array of customers
	 */
	mountCustomers(data){
		return data.map(customerInfo =>{
			return {
				...customerInfo,
				hasApp			:	Math.random() >= 0.3, 
				pax 			:	{
					adult		:	Math.floor(Math.random() * 3) + 1 ,
					children	:	Math.floor(Math.random() * 4),
					infant		:	Math.floor(Math.random() * 2)
				},
				lodgingInfo		:	this.lodgingCtrl.getRandomLodging()
			}					
		});
	}

	/**
	 * Get the information of the lodging associated to the booking
	 * @param listBookings list bookings
	 */
	async getLodgingFromBooking(listBookings){
		let bookings	=	await Promise.all(listBookings.map(async booking => {
			if(booking.lodgingInfo){	booking.lodgingInfo	=	await this.firebaseCtrl.getDataFromRef(booking.lodgingInfo);}
			return booking;
		}));
		return bookings;
	}
	/**
	 * Filter the bookings which has a customer assigned, and mount the objects. If the booking has a customer, pick customers info, otherwise pick the original info.
	 * @param customers list customers who logged on our system
	 * @param bookings list bookings from ttoo
	 */
	_mountCustomers(customers, bookings){
		let customersWithoutApp	=	bookings.filter(booking => !customers.some(customer => customer.current == booking.reference));
		let mountedCustomers 	=	[];

		bookings.forEach((booking, index) =>{
			let bookingNoApp	=	customersWithoutApp.find(el => el.reference == booking.reference);
			if(bookingNoApp){
				mountedCustomers.push({
					...booking,
					hasApp			:	false,
					info			:	this.mountInfoCustomer(booking),
					pax				:	this.mountPax(booking),
					lodgingInfo		:	booking.lodgingInfo ? this.lodgingCtrl.normalizeLodgingInfo(booking.lodgingInfo)
															: (booking.reference == 'K1611267' 	? this.lodgingCtrl.getConcreteLodging(1) 
																								: this.lodgingCtrl.getRandomLodging())
				})
			}else{
				let customer	=	customers.find(el => el.current == booking.reference);
				mountedCustomers.push({
					...booking,
					info			:	customer.info,
					hasApp			:	true,
					pax				:	this.mountPax(booking),
					lodgingInfo		:	booking.lodgingInfo ? this.lodgingCtrl.normalizeLodgingInfo(booking.lodgingInfo) 
															: (booking.reference == 'K1611267' 	? this.lodgingCtrl.getConcreteLodging(1) 
																								: this.lodgingCtrl.getRandomLodging())
				})
			}
		});
		return mountedCustomers;
	}

	mountInfoCustomer(booking){
		let formattedName	=	String(booking.customer.substr(booking.customer.indexOf(" ") + 1)).toLowerCase();
		return {
			avatar		:	null,
			name		:	formattedName,
			fullName	:	formattedName,
			gender		:	null,
			surname		:	null,

		}
	}
	mountPax(booking){
		return {
			adult		:	+booking.adults		||	1,
			children	:	+booking.children 	|| 	0,
			infant		:	+booking.infants	||	0
		}
	}
	getTotalPax(listBookings){
		listBookings	= listBookings || [];
		let types		= ['adult','children', 'infant'];
		// return types.map((type) => {
		// 	listBookings
		// 			.reduce((acc, o) => {
		// 				acc += (o && o.pax)?o.pax[type]:0;
		// 				return acc;
		// 			}, 0);
		// 			// .reduce((a,b)=>a+b,0)
		// });
		return -1;
	}
	/**
	 * 
	 * @param customers array of customers once are processed to mountCustomers method
	 */
	mountLodgings(customers){
		return [];
		let uniquesLodging	=	Array.from(new Set(customers.map(el => el.lodgingInfo.google_id))); // get just once appereance from each lodging
		
		let mountedLodgings	=	uniquesLodging.map((lodgingId : any) => {
			let lodging					=	customers.find(el => el.lodgingInfo.google_id == lodgingId).lodgingInfo;
			this.slidingCtrl.setDefaultSliders('lodgings', lodging);
			let customersFromLodging	=	customers.filter(el => el.lodgingInfo.google_id == lodgingId);
			let totalPax				=	{
				adult		:	customersFromLodging.reduce((acc, obj) => acc + (obj.pax['adult'] 		|| 0), 0),
				children	:	customersFromLodging.reduce((acc, obj) => acc + (obj.pax['children'] 	|| 0), 0),
				infant		:	customersFromLodging.reduce((acc, obj) => acc + (obj.pax['infant'] 		|| 0), 0),
			}
			return {
				lodging		:	lodging,
				customers	:	customersFromLodging,		
				sumPax		:	totalPax	
			}
		});
		
		return mountedLodgings;	
	}
	
	assignFakeLocation(){	return this.pageInfo.airportPoints[Math.floor(Math.random() * this.pageInfo.airportPoints.length)];	}

	getDefaultAvatar(){
		return 'assets/imgs/defaultAvatar.png';
	}
	getStatusIcon(status){
		return this.pageInfo.imgStatusCustomer[status] || this.pageInfo.imgStatusCustomer['unknown'];
	}
	getLocationIcon(enabled){
		return enabled ? this.pageInfo.imgLocation.enabled : this.pageInfo.imgLocation.disabled;
	}

	getImg(img){
		return this.pageInfo.imgs[img];
	}

	getListCheckeds(status, listCustomers){
		if(!listCustomers)	{	return null;	}
		let statusToCheck	= null;
		switch(status){
			case 'arrival_pickup_checkin'	: statusToCheck	=	'arrival_checked'; 		break;
			case 'departure_pickup_checkin'	: statusToCheck	=	'departure_checked';	break;
        	case 'departure_delivery'       : statusToCheck =  	'departure_delivered';	break;
			default							: statusToCheck	=	null;	
		}
		let filteredCompleted	=	listCustomers.filter(el => el.customer_status == statusToCheck);
		return {
			completed	:	this.getTotalPax(filteredCompleted),
			total		:	this.getTotalPax(listCustomers)
		}
	}
}
