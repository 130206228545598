import { Component, Input } from '@angular/core';

/**
 * Generated class for the AppInfoDistanceComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'app-info-distance',
  templateUrl: 'app-info-distance.html'
})
export class AppInfoDistanceComponent {

  
  @Input() distance			: any		= null;
  @Input() time				: any 		= null;
  @Input() unitDistance		: String 	= 'Km';
  @Input() unitTime     	: String 	= 'Min';
  @Input() displayRestart	: Boolean	= true;

  constructor() {
  }

}
