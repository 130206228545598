import { NgModule 						} from '@angular/core';
import { IonicModule 					} from '@ionic/angular';
import { TranslateModule 				} from '@ngx-translate/core';
import { AgmCoreModule 					} from '@agm/core';
import { AgmDirectionModule 			} from 'agm-direction';

import { CommonModule 					} from '@angular/common';
import { FormsModule 					} from '@angular/forms';
import { TestComponent 					} from './test/test.component';

import { RouteItemComponent 			} from './route-item/route-item';
import { CustomerItemComponent 			} from './customer-item/customer-item';
import { LodgingItemComponent 			} from './lodging-item/lodging-item';
import { StepperComponent 				} from './stepper/stepper';
import { CheckListComponent 			} from './check-list/check-list';
import { TouriniaHeaderComponent 		} from './tourinia-header/tourinia-header';
import { MapComponent 					} from './map/map';
import { InfoPanelComponent 			} from './info-panel/info-panel';
import { TypedChatComponent 			} from './typed-chat/typed-chat';
import { AppMultiSelectSegmentComponent	} from './app-multi-select-segment/app-multi-select-segment';
import { AppCalendarComponent 			} from './app-calendar/app-calendar';
import { AppGroupButtonsComponent 		} from './app-group-buttons/app-group-buttons';
import { AppSeparatorCalendarComponent 	} from './app-separator-calendar/app-separator-calendar';
import { AppReportCardComponent 		} from './app-report-card/app-report-card';
import { AppInvoiceCardComponent 		} from './app-invoice-card/app-invoice-card';
import { AppInfoDistanceComponent 		} from './app-info-distance/app-info-distance';
// import { CalendarComponent				} from 'ionic2-calendar';

@NgModule({
	imports: [	
		CommonModule,
		IonicModule,
		TranslateModule.forChild(),
		AgmCoreModule.forRoot(  {   apiKey		: 'AIzaSyBysI2NmB8WjIqpoiJkTnQU4Y_RRd6N9l0',
									libraries   : ["places","geometry"]}),
		AgmDirectionModule,
		// NgCalendarModule
	],
	declarations: [
		TestComponent,
		RouteItemComponent,
		CustomerItemComponent,
		LodgingItemComponent,
		StepperComponent,
		CheckListComponent,
		TouriniaHeaderComponent,
		MapComponent,
		InfoPanelComponent,
		TypedChatComponent,
		AppMultiSelectSegmentComponent,
		AppCalendarComponent,
		AppGroupButtonsComponent,
		AppSeparatorCalendarComponent,
		AppReportCardComponent,
		AppInvoiceCardComponent,
		AppInfoDistanceComponent,
		// CalendarComponent
	],
	exports: [
		TestComponent,
		RouteItemComponent,
		CustomerItemComponent,
		LodgingItemComponent,
		StepperComponent,
		CheckListComponent,
		TouriniaHeaderComponent,
		MapComponent,
		InfoPanelComponent,
		TypedChatComponent,
		AppMultiSelectSegmentComponent,
		AppCalendarComponent,
		AppGroupButtonsComponent,
		AppSeparatorCalendarComponent,
		AppReportCardComponent,
		AppInvoiceCardComponent,
		AppInfoDistanceComponent,
		// CalendarComponent
	]
})
export class ComponentsModule {}