import { TypedChatProvider } from 'src/app/providers/typed-chat/typed-chat';
import { Component, Input, Output, EventEmitter } from '@angular/core';

/**
 * Generated class for the TypedChatComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
	selector	: 'app-typed-chat',
	templateUrl	: 'typed-chat.html'
})
export class TypedChatComponent {
	
	@Input() 	listMessages	:	any;
	@Output()	itemSelected	=	new EventEmitter();
	@Output() 	restart			=	new EventEmitter();
	pageInfo	:	any	=	{};
	constructor( private typeChatCtrl	:	TypedChatProvider) {
		this.pageInfo	=	{
			gifs	:	{
				'loading'	:	'assets/imgs/loading.gif'
			}
		}
	}

	clickedItem(level,item){
		const clonedItem			=	JSON.parse(JSON.stringify(item));
		clonedItem.options.class	=	'standard-button disabled';	
		level.answered				=	item.id;
		level.items.find(el => el.id == item.id).options.class =	'standard-button disabled'; 
		this.itemSelected.emit({ level : level, item : clonedItem });	
	}
	
	getGif(gif){ this.typeChatCtrl.getGif(gif);	}
	
	getMessagesByRol(rol){
		if(!this.listMessages){return []};
		return this.listMessages.filter(el => el.rol == rol);
	}
	checkIfFinish(){
		if(!this.listMessages){return false};
		return this.listMessages[this.listMessages.length-1].items.length == 0
	}

	cancelResponse(level){
		this.itemSelected.emit({level : level, item : { action : 'cancelResponse'}})
	}
	restartChat(){
		this.restart.emit(true);
	}
}
