import { AuthGuardProvider 			} from './../auth-guard/auth-guard';
import { Storage 					} from '@ionic/storage';
import { Camera            			} from '@ionic-native/camera/ngx';
import { TranslateService    		} from '@ngx-translate/core';
import { ToastController, 
		 Platform, 
		 AlertController, 
		 LoadingController 			} from '@ionic/angular';
import { Injectable          		} from '@angular/core';
import { HttpClient 				} from '@angular/common/http';
import { ScreenOrientation   		} from '@ionic-native/screen-orientation/ngx';
import { routes 					} from './routes';1
import { FakeProvider 				} from '../fake/fake';
import { Vibration 					} from '@ionic-native/vibration/ngx';
import { statusInfo					} from './data/data';
// import { typesReports				} from '../../pages/reports-master/data/reports_type';
import * as moment 			  		  from 'moment';

import 'rxjs/add/operator/map';

@Injectable()
export class CommonsProvider {

	project				: string	= "Tourinia";
	version				: string	= "0.9.17";
	userInfo			: any		= {};
	simulation			: boolean 	= false;
	icons				: any 		= {			
		cards	: {
			unknown    	: "assets/img/cards/unknown.png"        ,
			none       	: "assets/img/cards/none.png"           ,
			visa       	: "assets/img/cards/visa_icon.png"      ,
			mastercard 	: "assets/img/cards/mastercard_icon.png",
			paypal 		: "assets/img/cards/paypal.png"
		}
	};
	imgs				: any	=	{
		timer			:	'assets/imgs/timer.png',
		unknown			:	'assets/imgs/unknown.png',
		
		cleans			:	'assets/imgs/cleaningIcon.jpg',
		meals			:	'assets/imgs/dietIcon.png',
		techs			:	'assets/imgs/technicalIcon.png',
		refuels			:	'assets/imgs/fuelIcon.png',
		
		defaultTicket	:	'assets/imgs/whiteTicket.png',
		addImage		:	'assets/imgs/addImage.png',
		touristIcon		:	'assets/imgs/touristIcon.png',
		stopIcon		:	'assets/imgs/stopIcon.png',
		routeIcon		:	'assets/imgs/routeIcon.jpg',
		reports			:	'assets/imgs/reports.png',
		services		:	'assets/imgs/services.png',

		landscapeSummer	:	'assets/imgs/landscapeSummer.jpg',
		landscapeWinter	:	'assets/imgs/landscapeWinter.jpg',
		landscapeAutumn	:	'assets/imgs/landscapeAutumn.jpg',
		landscapeSpring	:	'assets/imgs/landscapeSpring.jpg',
	}
	noImage             : String;
	noAvatar            : String;
	environment         : string = "prod";
	factories           : any = { 
		"dev"   : { url : "http://www.tourinia.com/app_dev.php"	},
		"prod"	: { url : "http://www.tourinia.com/app_dev.php"	}
  	};
  	nationalities		: string[] = [ "es", "uk", "us", "de", "fr", "it", "ru", "ro", "ch" ];
	badges				: any = {};

  	constructor(  
		private storage           	: Storage          	,
		// public  http              	: Http             	,
		public  http				: HttpClient,
		private toastCtrl         	: ToastController  	,
		private translate         	: TranslateService 	,
		private camera            	: Camera			,
		private screenOrientation 	: ScreenOrientation	,
		private platform          	: Platform         	,
		private fake              	: FakeProvider     	,
		// private events            	: Events			,
		private vibration			: Vibration			,
		public alertCtrl			: AlertController	,
		public loadingCtrl			: LoadingController	,
		private authGuard			: AuthGuardProvider	,
  	){}

	isCordova()			{	return this.platform.is('cordova');								}
	getFactory()		{	return this.factories[this.environment];						}
	getImg(img)			{	return this.imgs[img] ? this.imgs[img] : this.imgs['unknown'];	}
	getContent(doc) 	{	return doc.querySelector('ion-content');  						}
	resize(doc)			{	let content = this.getContent(doc); 
							if(undefined!=content && content.resize){ content.resize(); }
						}
	getStyles(){
		return {
			header	:	{
				background	:	'crimson',
				color		:	'white'
			}
		}
	}

	generateButtonsHeader(section?){
		return [
			// { title: '_ROUTE'		, 		name: "route"		,	visible : this.authGuard.accessGranted('CurrentServicePage'),	icon: 'ios-bus',				action:'currentService'				},
			{ title: '_MY_SERVICES'	, 		name: "myServices"	,	visible : this.authGuard.accessGranted('MyServicesPage'),		icon: 'ios-clipboard-outline',	action:'myServices'					},
			{ title: '_INVOICES', 			name: "invoices"	,	visible : this.authGuard.accessGranted('InvoicesMasterPage'),	icon: 'ios-filing', 			action:'myInvoices'					},
			{ title: '_REPORTS'	, 			name: "reports"		,	visible : this.authGuard.accessGranted('ReportsMasterPage'),	icon: 'ios-attach-outline',		action:'myReports', badge : 0		},
			{ title: '_ISSUES'	, 			name: "issues"		,	visible : this.authGuard.accessGranted('IssuesPage'),			icon: 'ios-alert-outline',		action:'issues'						},
			// { title: '_CHAT'	, 			name: "chat"		,	visible : true,		icon: 'ios-chatbubbles-outline',			action:'doReport'		}
		].map((item : any) => { item.selected = item.name == section;	return item;})
	}
  
	async getRequest(request:any)
	{
		// console.group("getRequest");
		let url 		= request instanceof Object ? request.fullUrl : request;
		let params		= request instanceof Object ? request.params  : {};
		let response	= await Promise.resolve(this.http.get(url,{ params: params }).toPromise());											
		// console.log("Response",response);
		// console.groupEnd();
		return response;
	}

	vibrate(){
		// this.debug("VIBRATE");
		// this.vibration.vibrate([10,10,10]);
		this.vibration.vibrate(100);
	}

	getRoute(route:string, params?:any)
	{    
		// console.group("getRoute");
		let current;

		switch(this.environment){
			case "prod":
				current = routes["remote"][routes["remote"][route]?route:"empty"];		// Get element
				current = (current instanceof Function) ? current(params):current;		// Eval if FN
				if(current instanceof Object){											// Add params
					current.fullUrl = this.getServerUrl().concat(current.url);
					//current.fullUrl.concat("?",Object.keys(current.params).reduce((acc,k)=>acc+"&"+k+"="+current.params[k],""));
				}
				break;		
			default:
			case "dev":
				current = routes["local"][routes["local"][route]?route:"empty"];
				if(current instanceof Object){
					current.fullUrl = this.getServerUrl().concat(current.url);					
					//current.fullUrl.concat("?",Object.keys(current.params).reduce((acc,k)=>acc+"&"+k+"="+current.params[k],""));
				}
				break;
		}
		// console.groupEnd();	
		return current;
	}

	getServerUrl(){
		// console.group("getServerUrl");

		if (undefined === this.getFactory() || undefined === this.getFactory().url ){
			// console.groupEnd();
			return false;
		}

		// console.groupEnd();
		return this.getFactory().url;
	}

	setBadge(type,key,value)
	{
		this.badges[type]				= this.badges[type] || {};
		this.badges[type][key] 			= value;
		this.badges[type]["services"] 	= 2;

		// this.events.publish('badge:'+type, this.badges[type] );
	}

	getBadges(type:string){
		return this.badges[type];
	}


	getFakeImage(){
		return this.fake.getBase64Image();
	}

  	initTranslate(_lang='es')
	{
		this.translate.setDefaultLang(_lang);
		this.storage.get('active_lang').then(lang =>{
      		console.log("LANG",lang);
			if(lang){
				this.translate.use(lang);
			} else {
				if (this.translate.getBrowserLang() !== undefined) {
         			console.log('BROWSER LANG', this.translate.getBrowserLang());
					this.translate.use(this.translate.getBrowserLang());
				} else {
					this.translate.use(_lang); // DEFAULT LANG
				}
			}
		});
  	}

	getCurrentLang(){		return this.translate.currentLang || this.translate.getBrowserLang();}

  	async getTranslate(info){
	    //return ( await Promise.resolve(this.translate.get([info]).toPromise()) )[info];
			let values = await Promise.resolve(this.translate.get([info]).toPromise());
	    return values[info];
  	}

  	translateDebug(info,type?,position?){
	    this.translate.get([info]).subscribe(values => {
	      this.debug(values[info],type,position);
	    });
  	}

  	debug(info,type?,position?){
    	this.print("[DEBUG] "+info,type,position);
  	}

  	translateWarning(info,type?,position?){
	    this.translate.get([info]).subscribe(values => {
	      this.warning(values[info],type,position);
	    });
  	}

  	warning(info,type?,position?){
    	this.print("[WARNING] "+info,type,position);
  	}

  	translateError(info,type?,position?){ 
	    this.translate.get([info]).subscribe(values => {
				// console.log("TRANSLATE ERROR",info,values[info]);
	      this.error(values[info],type,position);
	    });
  	}

  	error(info,type?,position?){
	    //this.print("[ERROR] "+info,type,position);
	    this.print(info,type,position);
  	}
  
  	translatePrint(info,type?,position?){
	    this.translate.get([info]).subscribe(values => {
	      this.print(values[info],type,position);
	    });
  	}

  	console(info){
    	console.log(info);
  	}

  	print(info,type,position){
	    switch(type){
	      default:
	      case "toast"		: this.showToast(info,position || "middle"); break;
	      case "alert"		: alert(info); break;
	      case "console"	: console.log(info); break;
	    }
  	}

	async showToast(str: string, position: any, duration?:number ) {
	    let toast = await this.toastCtrl.create({
	      message         : str,
	      duration        : duration || 2000,
	      position        : position || 'middle',
	    });
		toast.present();
	}
	
	async generateToast(str, options?){
		let toast =	await this.toastCtrl.create({
			message		:	await this.getTranslate(str),
			duration	:	options && options['duration'] ? options['duration'] :  1200,
			cssClass	:	options && options['cssClass']	? options['cssClass'] : 'success'
		});
		toast.present();
	}

	async createLoading(params?){
		// let loading	=	await this.loadingCtrl.create({
		// 	content	:	(params && params['content']) ? params['content'] : await Promise.resolve(this.getTranslate('_LOADING')) 
		// });
		// return loading;
		let loading = await this.loadingCtrl.create({ 
			message: await this.getTranslate('_GETTING_RESULTS')
		});
		return loading;
	}
	  

	async alertConfirm(title, subtitle?){
		return new Promise(async (resolve, reject) =>{
		const confirm	=	await this.alertCtrl.create({
			// title		: 	await Promise.resolve(this.getTranslate(title)),
			// title		: 	title,
			message		:	subtitle ? await Promise.resolve(this.getTranslate(subtitle)) : null,
			buttons		:	[
				{
					text	:	await Promise.resolve(this.getTranslate('_CANCEL')),
					handler	:	() =>{ 	confirm.dismiss().then(()=> resolve(false));
										return false
									}
				},
				{
					text	:	await Promise.resolve(this.getTranslate('_ACCEPT')),
					handler	:	() =>{ 	confirm.dismiss().then(()=> resolve(true)); 
										return false
									}
				}
			]
		});
		return confirm.present();
		});
	}

	toggleItem($event,data, property, multipleSelection?){
		let tmp	=	$event.selected ? !$event.selected : true;
		if (!multipleSelection) data.forEach(el => el[property] = false);
		$event.selected = tmp;
	} 
	
	async getAuthErrorMessage(code){
		console.log('ON COMMONS', code)
	switch(code){
		case 'auth/wrong-password'	:	return await Promise.resolve(this.getTranslate('_WRONG_PASSWORD'));
		case 'auth/invalid-email'	:	return await this.getTranslate('_INVALID_EMAIL');
		case 'auth/user-disabled'	:	return await this.getTranslate('_USER_DISABLED');
		case 'auth/user-not-found'	:	return await this.getTranslate('_USER_NOT_FOUND');
	}
	}

	/**
	 * Check params as defined and notEmpty
	 * @param info { defined: [], notEmpty[] }
	 */
  	checkParams(info){
	    let response = { success: true, error: "", item: "" };
	
	    // Check for params that needs to be just defined
	    for( let i in info.defined){
	      let value = info.params[info.defined[i]];
	      if ( undefined === value) {
	        response = { success : false, error: "PARAM_NOT_FOUND", item: info.defined[i] };
	        return response;
	      }
	    }
	
	    // Check for params that could not be empty
	    for( let i in info.notEmpty){
	      let value = info.params[info.notEmpty[i]];
	      if ( undefined === value || value == "" ) {
	        response = { success : false, error: "PARAM_COULD_NOT_BE_EMPTY", item: info.notEmpty[i] };
	        return response;
	      }
	    }
	    return response;
  	}

	//---------------------------------
	// DMC AND OTHER GENERAL INFO 
	//--------------------------------

	  async getInfoFlight(voucher){
		// let infoFlight 	=	this.getInformationFromFlight()
		// let infoFlight	=	{
		// 	gate	:	'D98',
		// 	belt	:	'01'
		// }
		// let infoObtained	=	await this.mapsCtrl.getCoordsFlight('PMI', infoFlight);	
		// return infoObtained;
	  }

	  getInfoDmc(){
		  return {
			  id			:	'1',
			  name			:	'Tourinia',
			  destination	:	'1'
		  }
	  }

	/**
	 * Take picture 
	 * @param source  < "camera" | "gallery " > 
	 * @param params 
	 * @param callback returns { img: jpeg/base64 }
	 */
  	takePicture(source,params,callback){    
	    let sourceType:any;
	
	    switch(source){
	      case "camera":
	        sourceType = this.camera.PictureSourceType.CAMERA;
	        break;
	      case "gallery":
	      default:
	        sourceType = this.camera.PictureSourceType.PHOTOLIBRARY;      
	    }
	
	    //let origOrientation = this.screenOrientation.type;
	
	    /*
	    this.debug("ORIENTATION->"+params.orientation,"alert");
	    
	    switch(params.orientation){
	      case "landscape":
	        this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.LANDSCAPE);
	        break;
	      case "portrait":
	        this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
	        break;
	    }
	    */
	    
	    this.camera.getPicture({
			sourceType            : sourceType,
			encodingType          : this.camera.EncodingType.JPEG,
			destinationType       : this.camera.DestinationType.DATA_URL,
			//cameraDirection       : 0,
			correctOrientation    : false,
			//quality               : 100,
			allowEdit             : true,
			//cameraDirection       : 0,
			//targetWidth           : 2048,
			//targetHeight          : 2048,
			saveToPhotoAlbum      : false
	    })
	    .then((ImageData)=>{
			callback({ img: 'data:image/jpeg;base64,'+ImageData });
			this.screenOrientation.unlock();
	    },(error)=>{
			this.error(error);
			this.screenOrientation.unlock();
	    });
  	}

  	oldTakePicture(source,params){    
	    let sourceType:any;
	
	    switch(source){
	      case "camera":
	        sourceType = this.camera.PictureSourceType.CAMERA;
	        break;
	      case "gallery":
	      default:
	        sourceType = this.camera.PictureSourceType.PHOTOLIBRARY;      
	    }
	
	    // let origOrientation = this.screenOrientation.type;	    
	
	    // switch(params.orientation){
	    //   case "landscape":
	    //     this.debug("LANDSCAPE");
	    //     this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.LANDSCAPE);
	    //     break;
	    //   case "portrait":
	    //     this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
	    //     break;
	    // }
	
	    this.camera.getPicture({
	      sourceType        : sourceType,
	      destinationType   : this.camera.DestinationType.DATA_URL
	    })
	    .then((ImageData)=>{
	      switch(params.type){        
	        case "field":
	          params.item[params.field] = 'data:image/jpeg;base64,'+ImageData;
	      }
	      this.screenOrientation.unlock();
	    },(error)=>{
	      this.error(error);
	      this.screenOrientation.unlock();
	    });
	  }
	  
	//------------------------------------
	// MOMENT AND DATE MANIPULATION
	// ------------------------------------
	
//------------------------------------
	// MOMENT AND DATE MANIPULATION
	// ------------------------------------
	
	/**
	 * Convert the object into timestamp
	 * @param element Object from firebase, can have seconds property or timestamp
	 */
	formatTimestamp(element){
		if(!element){return;}
		return element['seconds'] ? element['seconds']*1000 : element;
	}

	/**
	 * get date or today formated per YYYY-MM-DD by default
	 * 
	 * @param $date 
	 * @param $format 
	 * @returns 
	 */
	getDateFormatted($format="YYYY-MM-DD",$date?)	{
		return moment($date).format($format);
	}

	formatTimePassed(time){
		let today					=	moment(new Date()).startOf('day');
		let current					=	moment(new Date(this.formatTimestamp(time))).startOf('day');
		let daysDiff				=	today.diff(current, 'days');
		switch(true){
			case daysDiff == 0						:	return moment(this.formatTimestamp(time)).format('HH:mm');
			case daysDiff == 1						:	return '_YESTERDAY';
			case (daysDiff > 0) && (daysDiff <8)	:	return current.locale(this.getCurrentLang()).format('dddd');
			default									:	return current.format('DD/MM/YYYY');
		}
	}
		/**
	 * 
	 * @param time1 first date
	 * @param time2 second date
	 * @param interval unit difference between dates ( days, weeks, hours, minutes, etc)
	 */
	getDiffTime(time1,time2, interval){
		return moment(time1).diff(moment(time2),interval);		
	}

	formatCustomDate(date, format){
		return moment(date).format(format);
	}
	
	formatDate(date){	return moment(date);	}
	/**
	 * Return a computed date after add or substract a value
	 * @param time current time
	 * @param method add, substract
	 * @param amount unit number
	 * @param unitTime unitTime days, minute, hour, etc
	 */
	computeDate(time, method, amount, unitTime){
		try{
			return moment(time)[method](amount,unitTime)
		}catch(e){
			return '_INVALID_DATE';
		}
	}
	/**
	 * compare dates and determine if final is same or after than the original
	 * @param date1 origin date
	 * @param date2 final date
	 */
	dateIsSameOrAfter(date1,date2){
		try{
			if(!date1 || !date2){return false;}
			let first	=	moment(date1);
			let second	=	moment(date2);
			return second.isSameOrAfter(first);
		}catch(e){
			return false;
		}
	}

	/**
	 * Add unit time (hours, minutes, seconds, etc) to moment item
	 * @param moment 
	 * @param qty 
	 * @param type 
	 */
	addUnits(moment,qty,type){	return moment.add(qty,type);	}

	//**** END MOMENT AND DATE MANIPULATION ******* */

	getInfoStatusElements(status){
		return statusInfo.find(el => el.value == status) || statusInfo.find(el=> el.value == 'unknown');
	}

	getInfoTypes(typeReport, subtypeReport?){
		return [];
		// switch(typeReport){
		// 	case 'expenses':
		// 		return this._checkFindTypes(typesReports.expenses.subTypes.find(subType => subType.value == subtypeReport));
		// 	case 'free_days':
		// 		return this._checkFindTypes(typesReports.free_days);
		// }
	}

	getLabelTypeReport(typeReport)					{	return this._getFindLabel(this.getInfoTypeReport(typeReport));										}
	getLabelSubtypeReport(subtypeReport)			{	return this._getFindLabel(this.getInfoSubtypeReport(subtypeReport));								}
	getLabelType(type, typeReport, subtypeReport?)	{	return this._getFindLabel(this.getInfoType(type, typeReport, subtypeReport));						}

	getIconTypeReport(typeReport)					{	return this._getFindIcon(this.getInfoTypeReport(typeReport));										}
	getIconSubtypeReport(subtypeReport)				{	return this._getFindIcon(this.getInfoSubtypeReport(subtypeReport));									}
	getIconType(type, typeReport, subtypeReport?)	{	return this._getFindIcon(this.getInfoType(type, typeReport, subtypeReport));						}

	getInfoSubtypeReport(subtypeReport)				{
		return [];
		// return typesReports.expenses.subTypes.find(subType => subType.value == subtypeReport);				
	}
	getInfoType(type, typeReport, subtypeReport?)	{ 	return this.getInfoTypes(typeReport, subtypeReport).find(typeInfo => typeInfo.value == type);		}
	getInfoTypeReport(typeReport)					{
		return [];
		// return typesReports[Object.keys(typesReports).find(key => typesReports[key].value == typeReport)]	
	}

	_checkFindTypes(result) {	return (result || []).types || 	[];	}
	_getFindIcon(result) 	{	return (result || []).icon 	|| 	"";	}
	_getFindLabel(result) 	{	return (result || []).label || 	"";	}
	getListStatus()			{	return statusInfo;					}
}