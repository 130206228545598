import { NgModule 						} from '@angular/core';
import { BrowserModule 					} from '@angular/platform-browser';
import { RouteReuseStrategy 			} from '@angular/router';
import { IonicModule, 
		 IonicRouteStrategy 			} from '@ionic/angular';
import { AppComponent 					} from './app.component';
import { AppRoutingModule 				} from './app-routing.module';
import { environment 					} from '../environments/environment';
import { AngularFireAuthModule 			} from '@angular/fire/auth';
import { AngularFirestoreModule			} from '@angular/fire/firestore';
import { AngularFireModule 				} from '@angular/fire';
import { HttpClient, 
		 HttpClientModule 				} from '@angular/common/http';
import { IonicStorageModule 			} from '@ionic/storage';
import { TranslateLoader, 
		 TranslateModule 				} from '@ngx-translate/core';
import { TranslateHttpLoader			} from '@ngx-translate/http-loader';
import { Vibration 						} from '@ionic-native/vibration/ngx';
import { Camera 						} from '@ionic-native/camera/ngx';
import { StatusBar 						} from '@ionic-native/status-bar/ngx';
import { SplashScreen 					} from '@ionic-native/splash-screen/ngx';
import { CommonsProvider 				} from './providers/commons/commons';
import { Geolocation 					} from '@ionic-native/geolocation/ngx';
import { ScreenOrientation 				} from '@ionic-native/screen-orientation/ngx';
import { FakeProvider 					} from './providers/fake/fake';
import { AuthGuardProvider 				} from './providers/auth-guard/auth-guard';
import { CompanyProvider 				} from './providers/company/company';
import { FirebaseControllerProvider 	} from './providers/firebase-controller/firebase-controller';
import { LocatorProvider 				} from './providers/locator/locator';
import { FirebaseRoutesProvider 		} from './providers/firebase-routes/firebase-routes';
import { FirebaseIssuesProvider 		} from './providers/firebase-issues/firebase-issues';
import { FirebaseChatProvider 			} from './providers/firebase-chat/firebase-chat';
import { RouteProvider 					} from './providers/route/route';
import { LodgingProvider 				} from './providers/lodging/lodging';
import { SlidingProvider 				} from './providers/sliding/sliding';
import { MomentProvider 				} from './providers/moment/moment';
import { ComponentsModule 				} from './components/components.module';
import { TypedChatProvider } from './providers/typed-chat/typed-chat';
import { VehicleProvider } from './providers/vehicle/vehicle';
// import { NgCalendarModule 				} from 'ionic2-calendar';

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
  }
    
@NgModule({
  	declarations	: [ 
		AppComponent
	],
  	imports			: [
		BrowserModule,
		// NgCalendarModule,
		IonicModule.forRoot(),
		AppRoutingModule,
		
		// BrowserModule, 
		// IonicModule.forRoot(),
		// AppRoutingModule,
		// provideFirebaseApp	(() => initializeApp(environment.firebase)),
		// provideAuth			(() => getAuth()),
		// provideFirestore	(() => getFirestore())
	  	// IonicModule.forRoot(AppComponent),
		// AngularFireModule.initializeApp(environment.firebaseConfig),
		// AngularFireAuthModule,
		// AngularFirestoreModule,
		ComponentsModule,
		
		AngularFireModule.initializeApp(environment.firebase),
		AngularFirestoreModule,
		AngularFireAuthModule,
		HttpClientModule,
		IonicStorageModule.forRoot(),
		TranslateModule.forRoot({ 
			loader: {  
				provide		: TranslateLoader, 
				useFactory	: (createTranslateLoader),  
				deps		: [HttpClient] 
			} 
		}) 
	],
  	providers		: [

		// provide	: RouteReuseStrategy, 
		// useClass: IonicRouteStrategy,
		// StatusBar,
		// SplashScreen,
		// AngularFireDatabase,
		// { provide: ErrorHandler, useClass: IonicErrorHandler},
		// FirebaseControllerProvider,
		// CommonsProvider
		StatusBar,
		SplashScreen,
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		Camera,
		Vibration,
		Geolocation,
		ScreenOrientation,
		FakeProvider,
		AuthGuardProvider,
		CompanyProvider,
		FirebaseControllerProvider,
		LocatorProvider,
		RouteProvider,
		LodgingProvider,
		MomentProvider,

		CommonsProvider,
		FirebaseRoutesProvider,
		FirebaseIssuesProvider,
		FirebaseChatProvider,
		SlidingProvider,
		RouteProvider,
		VehicleProvider,
		TypedChatProvider
	],
  	bootstrap		: [ AppComponent ]
})
export class AppModule {}