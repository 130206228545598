import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { CommonsProvider } from '../commons/commons';

/*
  Generated class for the FirebaseIssuesProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class FirebaseIssuesProvider {

  constructor(	private afs 	: 	AngularFirestore,
		  		private commons	:	CommonsProvider,
            	) {
    console.log('Hello FirebaseIssuesProvider Provider');
  }

  subscribeToIssues(driverId){
		let dmcInfo				=	this.commons.getInfoDmc();
		console.log('get issues', driverId);
		return this.afs.collection('test').doc(dmcInfo.id).collection('destinations').doc(dmcInfo.destination).collection('issues',ref => ref.where('driverId','==',driverId)).valueChanges();
	}
	async addNewIssue(issue){
		let dmcInfo	=	this.commons.getInfoDmc();
		return await Promise.resolve(this.afs.collection('test').doc(dmcInfo.id).collection('destinations').doc(dmcInfo.destination).collection('issues').add(issue));
	}

}
