export const lodgings = [{
    	address		: "Crta Alcudia Arta, s/n, 07458 Can Picafort, Mallorca,Islas Baleares, Islas Baleares, España",
		google_id	: "ChIJXTVg-Y8ylhIROsVhGhu_hKQ",
		location	: {lat: 39.76333369999999, lng: 3.148750800000016},
		lodging		: "Tonga Tower Design Hotel and Suites",
		municipality: "Can Picafort, Mallorca,Islas Baleares",
		phone		: "+34 971 85 00 00",
		postal_code	: "07458",
		website		: "http://www.bghotels.com/",
		img			: "https://lh3.googleusercontent.com/p/AF1QipMTgdSWfOma7FkF8ZYuCjxxQbPmoE0aA5i19I2l=s1600-w4752",
		id			: 0,
		zone		: null,
	},{
    	address		: "Via França, 18, 07458 Can Picafort, Illes Balears, España",
    	google_id	: "ChIJF6Sl9y0zlhIRBdRLAJV0YYo",
    	location	: {lat: 39.76631980000001, lng: 3.1517770000000382},
    	lodging		: "Hotel JS Yate",
    	municipality: "Can Picafort",
    	phone		: "+34 971 85 00 27",
    	postal_code	: "07458",
		website		: "http://www.jshotels.com/",
		img			: "https://lh3.googleusercontent.com/p/AF1QipPd4jTRiRSRPM7L2ZWG-pmbYyfEJMoIwxFTWIOF=s1600-w6720",
		id			: 1,
    	zone		: null,
	},
	{ 
    	address		: "Av. Diagonal, 4, 07458 Can Picafort, Illes Balears, España",
		google_id	: "ChIJ4yIw8xAylhIRhD589kvGlP4",
		location	: {lat: 39.75969269999999, lng: 3.1674394000000348},
		lodging		: "Hotel & Spa Ferrer Janeiro",
		municipality: "Can Picafort",
		phone		: "+34 971 85 00 06",
		postal_code	: "07458",
		website		: "http://www.hotelferrerjaneiro.com/",
		img			: "https://www.ferrerhotels.com/idb/12533/1473927165867.jpg",
		id			: 2,
		zone		: null
	},
	{
    	address		: "Carrer d'En Serra, 23, 07400 Alcúdia, Illes Balears, España",
		google_id	: "ChIJ51yElFgrlhIRMKTTN5gjqzs",
		location	: {lat: 39.8521047, lng: 3.1223966999999675},
		lodging		: "Alcúdia Petit Hotel",
		municipality: "Alcúdia",
		phone		: "+34 971 51 52 60",
		postal_code	: "07400",
		website		: "http://www.alcudiapetithotel.com/",
		img			:  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTo1-jkhYOyHLImr73E5m45ki7FPDFiVRStwTYtNxue2m0Hf-6q",
		id			: 3,
		zone		: null
	},
	{
    	address		: "Plaça Pompeu Fabra, 7, 07400 Alcúdia, Illes Balears, España",
		google_id	: "ChIJ9TjMIRUslhIRv-fkLKds-Qw",
		location	: {lat: 39.837879, lng: 3.1587010000000646},
		lodging		: "Hotel Som Far",
		municipality: "Alcúdia",
		phone		: "+34 971 54 92 66",
		postal_code	: "07400",
		website		: "https://somhotels.es/hotel-som-far-in-majorca/",
		img			: "https://lh5.googleusercontent.com/p/AF1QipOeLnbIN5kZCugXJQZ7EmGqFsQ7v4uueEh1JZQi=w240-h160-k-no",
		id			: 4,
		zone		: null
	},{
    	address		: "Calle Colón, 265, 07470 Pollença, Islas Baleares, España",
		google_id	: "ChIJq-zgbzLVlxIR_vhsuWEKRQg",
		location	: {lat: 39.909933, lng: 3.0944959999999355},
		lodging		: "Hotel Illa d´Or",
		municipality: "Pollença",
		phone		: "+34 971 86 51 00",
		postal_code	: "07470",
		website		: "http://www.hotelillador.com/",
		img 		: "https://s-ec.bstatic.com/images/hotel/max1024x768/475/47545278.jpg",
		id			: 5,
		zone		: null
	}
]