export let routes : any = {
	mock     	: {
		empty         	: "assets/data/empty.json",
		mainPageInfo  	: "assets/data/mainPageInfo.json",
		execs         	: "assets/data/execs.json",
		customers	  	: "assets/data/customers.json",
		groups			: "assets/data/groups.json",
		tours			: "assets/data/tours.json",
		tour			: "assets/data/tour.json",
		services		: "assets/data/services.json",
		nationalities	: "assets/data/nationalities.json",
		providers		: "assets/data/providers.json",
		reps			: "assets/data/reps.json",
		cards			: "assets/data/cards.json",
		lodgings		: "assets/data/lodgings.json",
		treeZones		: "assets/data/treeLodgings.json",
		treeAreas		: "assets/data/treeLodgings.json",
		treeLodgings	: "assets/data/treeLodgings.json",
		scheduler      	: "assets/data/schedulers.json",
		pov_list		: "assets/data/map/pov.json",
	},
	local     	: {
		empty         	: "assets/data/empty.json",
		mainPageInfo  	: "assets/data/mainPageInfo.json",
		execs         	: "assets/data/execs.json",
		customers	  	: "assets/data/customers.json",
		groups			: "assets/data/groups.json",
		tours			: "assets/data/tours.json",
		tour			: "assets/data/tour.json",
		services		: "assets/data/services.json",
		nationalities	: "assets/data/nationalities.json",
		providers		: "assets/data/providers.json",
		reps			: "assets/data/reps.json",
		cards			: "assets/data/cards.json",
		lodgings		: "assets/data/lodgings.json",
		treeZones		: "assets/data/treeLodgings.json",
		treeAreas		: "assets/data/treeLodgings.json",
		treeLodgings	: "assets/data/treeLodgings.json",
		pov_list		: "assets/data/map/pov.json",
		scheduler      	: { method: "GET", url: "/entity/tour/scheduler", params: { format: "json" }}
	}, 
	remote		: {
		empty         	: { method: "GET", url: "/empty?id=1", params: {}},
		tourInfo		: (params)=>({ method: "GET", url: "/entity/tour/info/"+params["tour"], 		params	:	{ 	format: "json" }}),
		variantInfo		: (params)=>({ method: "GET", url: "/entity/tour/variant/info/"+params["id"],	params 	: Object.assign(params, {format : "json"})}), //Params : mode, lang
		variants		: (params)=>({ method: "GET", url: "/entity/tour/variant", 						params 	: Object.assign(params, {format : "json"})}), //Params : zone ( id ) 
		mainPageInfo  	: "assets/data/mainPageInfo.json",
		execs         	: "assets/data/execs.json",
		customers	  	: "assets/data/customers.json",
		groups			: "assets/data/groups.json",
		tours			: { method: "GET", url: "/entity/tour/", params: { format: "json" }},
		tour			: "assets/data/tour.json",
		services		: "assets/data/services.json",
		nationalities	: "assets/data/nationalities.json",
		providers		: "assets/data/providers.json",
		reps			: "assets/data/reps.json",
		cards			: "assets/data/cards.json",
		lodgings		: "assets/data/lodgings.json",
		treeZones		: "assets/data/treeLodgings.json",
		treeAreas		: "assets/data/treeLodgings.json",
		treeLodgings	: "assets/data/treeLodgings.json",
		pov_list		: "assets/data/map/pov.json",
		scheduler      	: (params)=>({ method: "GET", url: "/entity/tour/scheduler", params: Object.assign( params, { format: "json" })}),
		voucher 		: (params)=>({ method: "GET", url:"/dmc/api/v1.0/booking", params: Object.assign(params, {format:'json', mode: 'fastfull'})})
	}
}