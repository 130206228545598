import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {	path: '',					redirectTo: 'login',	pathMatch: 'full'																						},
  { path: 'login',      		loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)      									},
  { path: 'init',       		loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)      									},
  { path: 'details',    		loadChildren: () => import('./pages/details/details.module').then( m => m.DetailsPageModule)  									},
  {	path: 'bill-details',		loadChildren: () => import('./pages/bill-details/bill-details.module').then( m => m.BillDetailsPageModule)						},
  {	path: 'issues',				loadChildren: () => import('./pages/issues/issues.module').then( m => m.IssuesPageModule)										},
  {	path: 'my-services',		loadChildren: () => import('./pages/my-services/my-services.module').then( m => m.MyServicesPageModule)							},
  {	path: 'simulation-settings',loadChildren: () => import('./pages/simulation-settings/simulation-settings.module').then( m => m.SimulationSettingsPageModule)	},
  {	path: 'chat-master',		loadChildren: () => import('./pages/chat-master/chat-master.module').then( m => m.ChatMasterPageModule)							},
  {	path: 'invoices-detail',	loadChildren: () => import('./pages/invoices-detail/invoices-detail.module').then( m => m.InvoicesDetailPageModule)				},
  {	path: 'reports-detail',		loadChildren: () => import('./pages/reports-detail/reports-detail.module').then( m => m.ReportsDetailPageModule)				},
  {	path: 'tabs',				loadChildren: () => import('./pages/tabs/tabs.module').then( m => m.TabsPageModule)												},
  {	path: 'current-service',	loadChildren: () => import('./pages/current-service/current-service.module').then( m => m.CurrentServicePageModule)				},
  {	path: 'invoices-master', 	loadChildren: () => import('./pages/invoices-master/invoices-master.module').then( m => m.InvoicesMasterPageModule)				},
  { path: 'my-profile',			loadChildren: () => import('./pages/my-profile/my-profile.module').then( m => m.MyProfilePageModule)							},
  {	path: 'reports-master',		loadChildren: () => import('./pages/reports-master/reports-master.module').then( m => m.ReportsMasterPageModule)				},
  {	path: 'test',				loadChildren: () => import('./pages/test/test.module').then( m => m.TestPageModule)												}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
