import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lodgings    } from './data/data'
/*
  Generated class for the LodgingProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class LodgingProvider {
	pageInfo : any = {};
	constructor(public http: HttpClient) {
		this.pageInfo.lodgings = lodgings;
  	}
	
	getRandomLodging(index?)			{	return this.pageInfo.lodgings[Math.floor(Math.random() * this.pageInfo.lodgings.length)];	}
	getConcreteLodging(position)		{	return this.pageInfo.lodgings[position]														}
	normalizeLodgingInfo(lodgingInfo)	{
		lodgingInfo['address']		=	lodgingInfo['formatted_address'];
		lodgingInfo['location']		=	{ lat : lodgingInfo['latitude'], lng : lodgingInfo['longitude']};
		lodgingInfo['municipality']	=	this.getInfoFromAddress('municipality',lodgingInfo['address_components']) || null;	
		lodgingInfo['phone']		=	"+34 971 51 52 60", // TODO GET PHONE FROM GOOGLE BOOKINGS 
		lodgingInfo['postal_code']	=	this.getInfoFromAddress('postal_code',lodgingInfo['address_components']) || null,
		lodgingInfo['website']		=	"http://www.tourinia.com/", // TODO GET WEBSITE
		lodgingInfo['img']			=	"https://r-cf.bstatic.com/images/hotel/max1024x768/204/204258104.jpg" || lodgingInfo['photo'],
		lodgingInfo['id']			=	lodgingInfo['place_id'],
		lodgingInfo['zone']			=	lodgingInfo['zone']	|| null;
		lodgingInfo['lodging']		=	lodgingInfo['name'];
		return lodgingInfo;
	}

	getInfoFromAddress(entity, address_components){
		if(!address_components){ return null}
		switch(entity){
			default				:	return null;
			case 'municipality'	:	return (address_components.find(item => item.types.some(type => type == 'municipality' || type == 'locality')) || {}).long_name;
			case 'postal_code'	:	return (address_components.find(item => item.types.some(type => type == 'postal_code')) || {}).long_name;
		}
	}

}
