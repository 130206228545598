import { Component, Input } from '@angular/core';

/**
 * Generated class for the StepperComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector		: 'app-stepper',
  templateUrl	: 'stepper.html',
  styleUrls		: ['./stepper.scss']
})
export class StepperComponent {

	@Input() steps	:	any  = [];
	pageInfo		:	any  = {
		colors	:	{
			active	:	'crimson',
			past	:	'crimson',
			pending	:	'gray'
		}
		
	}
	constructor() {
  	}

	getColorStep(step){
		if(step.active){ return this.pageInfo.colors.active; }
		let currentActive	=	this.steps.find(el => el.active);
		return step.id > currentActive.id ? this.pageInfo.colors.pending : this.pageInfo.colors.past;	
	}
}
