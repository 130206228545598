import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/*
  Generated class for the CompanyProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class CompanyProvider {
	pageInfo : any = {
		companyContractedModules	: [{
			value 		: 'app_transport',
			sections	: [{id : 1, selected : true, value : 'routes'}]	
		}]
	}
	

	constructor(public http: HttpClient) {}
	getModulesHired()	{	return this.pageInfo.companyContractedModules	}

}
