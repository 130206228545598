import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Geolocation } from '@ionic-native/geolocation/ngx';

/*
  Generated class for the LocatorProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
interface Point { lat: any, lng: any };

@Injectable()
export class LocatorProvider {

	watch	:	any;
	public info	:	any = {};
	options = { maximumAge: 3000, timeout: 30000, enableHighAccuracy: true };

	constructor(private geolocation 	: Geolocation) {
	}

	init(){	this.info.me	= { lat: 0, lng: 0};}
	getLocation(){
		return new Promise((resolve, reject) =>{
			this.geolocation.getCurrentPosition()
			.then((resp : any) => {
				resolve(resp);
				this.setPosition({lat : resp.coords.latitude, lng : resp.coords.longitude});
			}).catch((error) => {
				console.log('Error getting location', error);
				reject(error);
			 });
		})
	}
	startTracking(){

		this.watch = this.geolocation.watchPosition(this.options);

		this.watch.subscribe((data) => {
			if(data.coords){
				this.setPosition({lat : data.coords.latitude, lng : data.coords.longitude});
			}
		});	   
	}

	setPosition(position:{lat:any,lng:any}){
		this.info.me = position;
	}
	
	/**
	 * Check distance between two geopoints in meters
	 * @param me 
	 * @param p 
	 */
	checkDistance(me:Point,p:Point)
	{  
		var R 		= 6378.137; // Radius of earth in KM
		var dLat 	= p.lat * Math.PI / 180 - me.lat * Math.PI / 180;
		var dLng 	= p.lng * Math.PI / 180 - me.lng * Math.PI / 180;
		var a 		= Math.sin(dLat/2) * Math.sin(dLat/2)
					    + Math.cos(me.lat * Math.PI / 180) 
						* Math.cos(p.lat * Math.PI / 180) 
						* Math.sin(dLng/2) 
						* Math.sin(dLng/2);

		var c 		= 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
		var d 		= R * c;

		// Return value in meters
		return d * 1000;
	}
}
