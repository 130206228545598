import { MomentProvider } from 'src/app/providers/moment/moment';
import { Component, Input, OnInit } from '@angular/core';
import { CommonsProvider } from 'src/app/providers/commons/commons';

/**
 * Generated class for the AppSeparatorCalendarComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'app-separator-calendar',
  templateUrl: 'app-separator-calendar.html'
})
export class AppSeparatorCalendarComponent implements OnInit {


  @Input() month    : any
  @Input() idMonth  : any;
  constructor(private commons : CommonsProvider, private momentCtrl : MomentProvider) {
  }
  
  ngOnInit(){
    this.getImg();
  }
  getImg(){
    switch(true){
      case this.idMonth >= 0 && this.idMonth <= 2 : return this.commons.getImg('landscapeWinter');
      case this.idMonth >= 3 && this.idMonth <= 5 : return this.commons.getImg('landscapeSpring');
      case this.idMonth >= 6 && this.idMonth <= 8 : return this.commons.getImg('landscapeSummer');
      case this.idMonth >= 9 && this.idMonth <= 11 : return this.commons.getImg('landscapeAutumn');
      default : return this.commons.getImg('landscapeAutumn');
    }
  }
}
