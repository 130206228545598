import { 	Injectable 	} 	from '@angular/core';
import {	settings	}	from './settings/settings'
@Injectable()
export class SlidingProvider {

	pageInfo	:	any	=	{
		settings			:	{
			slidingItems	:	{
				defaultSide		:	'right',
				defaultClass	:	'standard-button-sliding'
			},
			actionStatus	:	{
				defaultClass	:	'standard-button steelblue'
			}
		}
	}
	constructor() {
		
	}

	/**
	 * Check the consistency of the optional parameters
	 * @param settings Settings to add into sliding option
	 */
	verifySlidingSettings(settings){

		let defaultSide		=	this.pageInfo.settings.slidingItems.defaultSide;
		let defaultClass	=	this.pageInfo.settings.slidingItems.defaultClass;

		return {
			side	:	settings ? (settings['side'] 	? settings['side'] 	: 	defaultSide) 	:	defaultSide,
			class	:	settings ? (settings['class'] 	? settings['class']	:	defaultClass) 	: 	defaultClass,
			icon	:	settings ? (settings['icon']	? settings['icon']	:	null)			:	null
		}
	}

	/**
	 * add the option into customers sliding options 
	 * @param customer 
	 * @param value value of the action
	 * @param label label tokenized 
	 * @param settings optional parameters like class, icon, side
	 */
	addSlidingItemOption(item,value,label,settings?){
		let verifiedSettings	=	this.verifySlidingSettings(settings);

		item.slidingOptions		=	item.slidingOptions || {sides : { left : [], right : []}};
		if(item.slidingOptions.sides[verifiedSettings.side].find(el => el.value == value)){ return;} // avoid duplicates

		item.slidingOptions.sides[verifiedSettings.side].push({
			label	:	label,
			icon	:	verifiedSettings.icon,
			class	:	verifiedSettings.class,
			value	:	value,
			type 	:	'button'
		});
	}

	removeSlidingItemOption(item,value){

		if(!item.slidingOptions){ return; }

		let foundLeft	=	this.findItemSide(item,value,'left');
		let foundRight	=	this.findItemSide(item,value, 'right');
		let foundItem	=	foundLeft.result > foundRight.result ? foundLeft : foundRight;
		if(foundItem.result > -1){
			item.slidingOptions.sides[foundItem.side].splice(foundItem.result,1);
			return;
		}
	}

	findItemSide(item,value, side){
		let result	=	 item.slidingOptions.sides[side].findIndex(el => el.value == value);
		return {
			side	:	side, 
			result	:	result
		}
	}
	
	setDefaultSliders(type, item){
		if(!settings[type]){ return item;}
		settings[type].forEach(option =>{
			if(option.active(item)){
				this.addSlidingItemOption(item,option.value, option.label, option['settings'])
			}
		})
	}
}
