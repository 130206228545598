import { CommonsProvider } from './../commons/commons';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { combineLatest } from 'rxjs';
import { importExpr } from '@angular/compiler/src/output/output_ast';

/*
  Generated class for the FirebaseControllerProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class FirebaseControllerProvider {

  	constructor(
		  private afs 			: 	AngularFirestore,
		  private commons		:	CommonsProvider,
		){}

	getDmcs()			{	return this.afs.collection('dmcs').doc('1').collection('bookings').valueChanges();	}
  
  	//Id set as a custom to demo => '333'
	getTrajectsById()	{	return this.afs.collection('trajects',(ref => ref.where('driver','==',"333")));		}
	getRef(ref)			{	return this.afs.doc(ref);															}
	
	async updateItemByRef(ref,object){
		if(!ref){
			console.log("[updateItemByRef] not ref",ref);
			return false;
		}
		let request	= await Promise.resolve(this.afs.doc(ref).update(object));
		return request;
	}

	subscribeMultiObservables(items){
    	const observables = items.map(item => this.getRef(item).valueChanges());
    	return combineLatest(observables)
	  }
	
	/**
	 * Given a list of array of references, it returns the data from each one as a promise resolved
	 * @param listItems array of firebase References
	 */
	async getPromiseFromMultiObservables(listItems){
		if(!listItems){
			console.log("No items to subscribe");
			return [];
		}
		return await Promise.all(listItems.map(async el => {
			let call	=	 await this.afs.doc(el).ref.get();
			return {...call.data(), ref	:	call.ref};
		}));
	}

	async getDataFromRef(ref){
		let call	=	await this.afs.doc(ref).ref.get();
		if(!call.exists || !call.data()){
			return [];
		}
		return call.data();
	}

	/**
	 * DRIVER METHODS
	 */
	async getDriverInfo(driverId){
		let dmcInfo			=	this.commons.getInfoDmc();
		console.log("VERIFY", driverId, dmcInfo);
		let driverInfo		=	await this.afs
											.collection('dmcs')
											.doc(dmcInfo.id)
											.collection('destinations')
											.doc(dmcInfo.destination)
											.collection('drivers')
											// .doc(parseInt(driverId).toString())
											.doc(driverId)
											.ref.get();										
		if(!driverInfo.exists){
			this.commons.generateToast("_DRIVER_DO_NOT_EXISTS");
			return [];
		};
		return {...driverInfo.data(), ref : driverInfo.ref};
	}

	async getDriverRoutes($params){
		let dmcInfo			=	this.commons.getInfoDmc();
		if(!dmcInfo || !dmcInfo.id || !dmcInfo.destination){
			this.commons.generateToast("ERROR","_DMCINFO_NO_DATA");
			return  [];
		}
		if(!$params["date"]){
			this.commons.generateToast("ERROR","_NO_DATE_FOUND");
			return  [];
		}
		if(!$params["vehicle_path"]){
			this.commons.generateToast("ERROR","_NO_DATE_FOUND");
			return  [];
		}
		
		let path			=	"dmcs/"+dmcInfo.id+"/destinations/"+dmcInfo.destination+"/solutions/"+$params["date"]+"/services/"+$params["vehicle_path"];
		let serviceInfo		=	await this.afs.doc(path).ref.get();
		
		if(!serviceInfo.exists || !serviceInfo.data()){
			// this.commons.generateToast("_SERVICE_INFO_NOT_FOUND");
			return [];
		};

		let results = serviceInfo.data().row;
		return (results||[]).length==0?[]:results.items;
	}

	public async getUserById(id){
		let dmcInfo		=	this.commons.getInfoDmc();
		const userInfo 	= await Promise.resolve(this.afs.doc('dmcs/'+dmcInfo.id+'/destinations/'+dmcInfo.destination+'/drivers/'+id).ref.get());
		return userInfo;
	}

	
}


