import { CompanyProvider } from './../company/company';
import { Injectable       } from '@angular/core';
import { permissions  	  } from './data/infopermissions'
/*
  Generated class for the AuthGuardProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class AuthGuardProvider {

	pageInfo	  	: any = {};
	constructor(private companyCtrl : CompanyProvider) {}
	getModulesHired()		{	return this.companyCtrl.getModulesHired();}

	accessGranted(page)		{	
		let modulesHired		=	this.getModulesHired();  
		let permissionsFromPage	=	permissions[page];	
		if(!permissionsFromPage || Object.keys(permissionsFromPage).length == 0){ return true; }

		let foundModule	=	modulesHired.find(module => module.value == permissionsFromPage.module);
		if(!foundModule){ return false; }
		return foundModule.sections.some(section => section.value == permissionsFromPage.section);
	}

}
