import { Storage } from '@ionic/storage';
import { MenuController, NavController 	} from '@ionic/angular';
import { Component, Input 						} from '@angular/core';
import { CommonsProvider } from 'src/app/providers/commons/commons';

@Component({
  selector		: 'tourinia-header',
  templateUrl	: 'tourinia-header.html',
  styleUrls		: ['tourinia-header.scss']
})
export class TouriniaHeaderComponent {

	@Input("showMenu"  ) showMenu  : boolean = true;
	@Input("showButler") showButler: boolean = true;

	pageInfo  : any     = {}   ;
	fabOpenned: boolean = false;
	openMenu  : boolean = false;

  	constructor(
	    private commons: CommonsProvider,
	    // private events: Events,
		private navCtrl: NavController,
		private storage: Storage
  	){ 
		this.pageInfo = {
			"logo": "assets/imgs/tourinia_logo.png",
			butler: {
			// icon: "assets/icons/butler.png",
			icon: "assets/imgs/assistant_mini.png",
			active: false,
			options: [
				{ name: "user", 			title: "_USER", 		icon: "person", 					action: "user" 		},
				{ name: "services", 		title: "_MY_SERVICES", 	icon: "ios-filing-outline", 		action: "services" 	},
				{ name: "current", 			title: "_ROUTE_ACTIVE ",icon: "checkmark-circle",			action: "current"	},
				// { name: "chat", 			title: "_CHATS", 		icon: "ios-chatbubbles-outline",	action: "chat" 		},

				// { name: "cards", 			title: "_PAYMENT_CARDS",icon: "ios-cash-outline", 			action: "wallet" 	},
				// { name: "issues", 			title: "_ISSUES", 		icon: "alert",				 		action: "issues" 	},
				// { name: "checkout", 		title: "_CHECKOUT", 	icon: "ios-paper-plane-outline",	action: "checkout" 	},

				// { name: "help", 			title: "_HELP", 		icon: "help", 						action: "help" 		},
				{ name: "config", 			title: "_PREFERENCES", 	icon: "md-cog", 					action: "config" 	},
				{ name: "logout", 			title: "_LOGOUT", 		icon: "log-out",					action: "logout" 	},
				// { name: "tours", 		title: "_TOURS", 		icon: "boat", 						action: "tours" 	},
				// { name: "activities", 	title: "_ACTIVITIES", 	icon: "bicycle", 					action: "activities"},
				// { name: "transfers", 	title: "_TRANSFERS", 	icon: "car", 						action: "transfers" },
			],
			getOptions: function (order?: string) {
				return order == "reversed" ? this.options.reverse() : this.options;
			}
			}
		};
      	// Set initial badges
		this.generateBadges(this.commons.getBadges("butler"));

	    // Get badges updates
	    // this.events.subscribe('badge:butler', (values) => {
		// 	this.generateBadges(values);
		// });
  	}

	generateBadges(values = {})
	{
		Object.keys(values).forEach(key => {
			this.pageInfo.butler.options.filter(item => item.name == key)
				.map(item => item.badge = values[key]);
			}
		);
		this.pageInfo.butler.badge = this.pageInfo.butler.options
										 .reduce((acc, item) => acc + (isNaN(item.badge)?0:item.badge), 0);
	}

	async goSection(section)
	{
		console.log("go Section " + section);
		this.togglePopupMenu();

	  	switch (section) {

		    case 'user':		this.navCtrl.navigateRoot('my-profile'); 				break;
			// case 'chat':		this.navCtrl.navigateRoot('ChatMasterPage'); 			break;
		    case 'services'	:	this.navCtrl.navigateRoot('my-services'); 				break;
		    // case 'wallet':		this.navCtrl.navigateRoot('CardMasterPage'); 				break;
		    // case 'tours':		this.navCtrl.navigateRoot('MyProfilePage'); 				break;
		    // case 'activities':	this.navCtrl.navigateRoot('ActivitiesPage'); 				break;
			case 'current':		this.navCtrl.navigateRoot('current-service');			break;
			// case 'issues':		this.navCtrl.navigateRoot('IssuesPage');					break;
			// case 'config':		this.navCtrl.navigateRoot('SettingsPage'); 					break;
			case 'config':		this.navCtrl.navigateRoot('simulation-settings'); 		break;
			// case 'help'		:	this.navCtrl.navigateRoot('HelpPage');						break;
		    // case 'checkout':	this.navCtrl.navigateRoot('CheckoutPage'); 					break;

			case 'logout':
				let userInfo		=	await Promise.resolve(this.storage.get('driverInfo')) || {};
				userInfo.isLogged	=	false;
				await Promise.resolve(this.storage.set('driverInfo',userInfo));
				await this.navCtrl.navigateRoot('LoginPage'); break;		    

		    // case 'logout':		this.navCtrl.navigateRoot('LoginPage',{ logout: true }); break;		    
			// case 'help'		:	this.navCtrl.navigateRoot('ListReportsPage');					break;
		    case 'checkout':	this.navCtrl.navigateRoot('CheckoutPage'); 					break;

		}
		  
	} 
	
	togglePopupMenu() {
		return this.openMenu = !this.openMenu;
	}
}
