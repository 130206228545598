import { CommonsProvider } from 'src/app/providers/commons/commons';
import { Component, Input, Output, EventEmitter } from '@angular/core';

/**
 * Generated class for the AppMultiSelectSegmentComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'app-multi-select-segment',
  templateUrl: 'app-multi-select-segment.html'
})
export class AppMultiSelectSegmentComponent {

  @Input() options        	: any[];
  @Input() typeSelection  	: 'single';	//single, multiple
  @Output()	emitter			=	new EventEmitter();

  constructor(private commons : CommonsProvider) {
  }

  clickOption(item){
	if(this.typeSelection == 'single'){
		this.commons.toggleItem(item,this.options,'selected');
	}else{
		item.selected = !item.selected;
	}
	this.emitter.emit(this.options);
	console.log(this.options);
  }
}
