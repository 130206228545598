
export const items      = 	{
	1	:	{ id : 1, label : '_REQ_LOCATION', 		type : 'item-option',	options	: { img : 'voucher'}, action : 'reqLocation', 	text_response	:	'_DRIVER_WANTS_YOUR_LOCATION' },
	2	:	{ id : 2, label : '_ARE_U_COMMING', 	type : 'item-option',	options	: { img : 'ticket'},							text_response	:	'_DRIVER_ASK_IF_YOU_WILL_COME' },
	3	:	{ id : 3, label : '_ASK_SOME_PROBLEM', 	type : 'item-option',	options	: {},											text_response	:	'_DRIVER_ASK_IF_SOME_PROBLEM'},

	4	:	{ id : 4, label : '_ACCEPT', 	type : 'item-option',	options	: {}, 	action : 'shareLocation',	text_response	:	'_CUSTOMER_SHARED_LOCATION'},
	5	:	{ id : 5, label : '_REJECT', 	type : 'item-option',	options	: {},	action : 'denyLocation',	text_response	:	'_CUSTOMER_DONT_SHARE_LOCATION', leaf : true},
	
	6	:	{ id : 6, label : '_COME_FOR_YOU?', type : 'item-option', options : {}, text_response : '_DRIVER_ASK_TO_COME_FOR_YOU'},

	7	:	{ id : 7, label : '_YES_COME_PLEASE', 	type : 'item-option', options : {}, text_response : '_CUSTOMER_ASKED_TO_CATCH_HIM', leaf : true},
	8	:	{ id : 8,label : '_NO_THANKS', 			type : 'item-option', options : {}, text_response : '_CUSTOMER_COME_TO_TRANSPORT', 	leaf : true},

	9	:	{ id : 9, label : '_LEAVE_IN_5_MIN', 	type : 'item-opion',	options : {}, text_response : '_TRANSPORT_LEAVE_IN_5_MIN', 		leaf : true},
	10	:	{ id : 10, label : '_LEAVE_IN_10_MIN', 	type : 'item-option', 	options : {}, text_response : '_TRANSPORT_LEAVE_IN_10_MIN', 	leaf : true},
	11	:	{ id : 11, label : '_LEAVE_IN_15_MIN', 	type : 'item-option', 	options : {}, text_response : '_TRANSPORT_LEAVE_IN_15_MIN', 	leaf : true},

	12	:	{ id : 12, label : '_YES_WE_COME', 		type : 'item-option', options : {}, text_response : '_YES_WE_ARE_COMMING'},
	13	:	{ id : 13, label : '_NO_WE_DONT_COME', 	type : 'item-option', options : {}, text_response : '_WE_ARE_NOT_COMMING'},
	
	14	:	{ id : 14, label : '_UNDERSTOOD', 	type : 'item-option', options : {}, text_response : '_TRANSPORT_IS_LEAVING', leaf : true},
	
	15	:	{ id : 15, label : '_BAGGAGE', 	type : 'item-option', options : {}, text_response : '_PROBLEM_WITH_BAGGAGE'},
	16	:	{ id : 16, label : '_INJURIES', type : 'item-option', options : {}, text_response : '_PROBLEM_WITH_INJURIES', 	},
	17	:	{ id : 17, label : '_LANDED', 	type : 'item-option', options : {}, text_response : '_PROBLEM_ON_LANDING', 		},
	18	:	{ id : 18, label : '_OTHERS', 	type : 'item-option', options : {}, text_response : '_OTHERS', 					},

	19	:	{ id : 19, label : '_WE_TAKE_CARE', 				type : 'item-option', options : {}, text_response : '_BAGGAGE_WE_TAKE_CARE', 	leaf : true},
	20	:	{ id : 20, label : '_OUR_COMPANY_WILL_RESOLVE', 	type : 'item-option', options : {}, text_response : '_COMPANY_WILL_TAKE_CARE', 	leaf : true},

	}
export const treeItems  =   {	
	0	:	{id	:	0,	items : [1,2,3],     	rol :   'driver',   options : { intro  : '_INTRO_CHAT',         			conclusion : '_PROCESSING'}, backnode: 999},
	1	:	{id	:	1,	items : [4,5], 			rol :   'customer', options : { intro : '_DRIVER_WANTS_YOUR_LOCATION', 		conclusion : '_GETTING_VOUCHER'}},
	2	:	{id	:	2,	items : [12,13],		rol	:	'customer',	options : { intro : '_CUSTOMER_SAID'}},
    3	:	{id	:	3,	items : [15,16,17,18],  rol :	'customer',	options : { intro : '_RESULT_FINISHED',action	:	'display_shops'}},
    4	:	{id	:	4,	items : [6,9,10,11],	rol :	'driver',	options : { intro : '_RESULT_FINISHED',action	:	'display_shops'}},
    6	:	{id	:	6,	items : [7,8],			rol :	'customer',	options : { intro : '_RESULT_FINISHED',action	:	'display_shops'}},
	12	:	{id	:	12,	items : [1,3],			rol	:	'driver',	options : { intro : '_CUSTOMER_SAID'}},
	13	:	{id	:	13,	items : [14],			rol	:	'driver',	options : { intro : '_CUSTOMER_SAID'}},
	15	:	{id	:	15,	items : [19,9,10,11],	rol	:	'driver',	options : { intro : '_CUSTOMER_SAID'}},
	16	:	{id	:	16,	items : [20,9,10,11],	rol	:	'driver',	options : { intro : '_CUSTOMER_SAID'}},
	17	:	{id	:	17,	items : [9,10,11],		rol	:	'driver',	options : { intro : '_CUSTOMER_SAID'}},
	18	:	{id	:	18,	items : [9,10,11],		rol	:	'driver',	options : { intro : '_CUSTOMER_SAID'}},

}   