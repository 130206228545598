import { MomentProvider } from 'src/app/providers/moment/moment';
import { CommonsProvider } from 'src/app/providers/commons/commons';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { statusInfo } from 'src/app/providers/commons/data/data';

@Component({
	selector	: 'app-report-card',
	templateUrl	: 'app-report-card.html'
})

export class AppReportCardComponent {

	@Input() 	template 	= 	null;
	@Input() 	report		=	null;
	@Input()	expandable	=	false;
	@Output()	emitter		=	new EventEmitter();

	diffDaysTime	:	any;
	constructor(private commons : CommonsProvider,private momentProvider : MomentProvider) {
	}

	selectOption(option)			{  this.emitter.emit({type : 'report', value : option});													}
	computeDay(day,format?)			{ if(!day){return '-'}; return this.momentProvider.formatDate(day, format ? format : 'YYYY-MM-DD');		}
	getInfoStatus(status)			{ return this.commons.getInfoStatusElements(status);													}
	getLabelType(report) 			{ return this.commons.getLabelType(report.type, report.typeReport, report.subtypeReport); 				}
	getLabelTypeReport(report) 		{ return this.commons.getLabelTypeReport(report.typeReport) 											}
	getLabelSubtypeReport(report) 	{ return this.commons.getLabelSubtypeReport(report.subtypeReport) 										}

	handleReport(){
		if(this.expandable){
			return  this.report.expanded = !this.report.expanded
		}else{
			this.selectOption(this.report);
		}
	}
	getHumanizedDiffTime(initTime, endTime){
		return this.momentProvider.getHumanizedDiffTime(initTime,endTime);
	}

	getDiffTime(initTime,endTime,value){
		return this.momentProvider.getDiffTime(initTime,endTime,value);
	}

	getSeparator(initTime,endTime,value) {
		return (this.isHours(initTime,endTime,value)) ? '_TO_HOURS' : '_TO_DAYS';
	}

	isHours(initTime,endTime,value){
		return Math.abs(this.getDiffTime(initTime,endTime,value)) < 1;
	}

	formatRangeDate(initTime,endTime,timeToFormat,value){
		let diffTime	=	this.getDiffTime(initTime,endTime,value);
		return Math.abs(diffTime) >= 1 ? this.computeDay(timeToFormat,'DD/MM') : this.computeDay(timeToFormat, 'HH:mm');
	}

	getCreationDay(report) {
		return this.computeDay(report.historical[0].day);
	}
	

}
