import { Injectable 				} from '@angular/core';
import { AngularFirestore 			} from '@angular/fire/firestore';
import { FirebaseControllerProvider } from '../firebase-controller/firebase-controller';
import { CommonsProvider 			} from '../commons/commons';
import { BehaviorSubject 			} from 'rxjs';
import { RouteProvider 				} from '../route/route';
import { AttrAst } from '@angular/compiler';

@Injectable()
export class FirebaseRoutesProvider {

	public routes					:	BehaviorSubject<any> = new BehaviorSubject<any>(null);
	public currentRoute				:	BehaviorSubject<any> = new BehaviorSubject<any>(null);
	public listRouteSubscription	:	any;

	constructor(	private afs 				: 	AngularFirestore,
					private firebaseControler	:	FirebaseControllerProvider,
					private commons				:	CommonsProvider,
					private routeCtrl			:	RouteProvider,
				) {
		console.log('Hello FirebaseRoutesProvider Provider');
	}

	/**
	 * Get the routes from a driver and subscribe to their events. 
	 * It's bad Idea because the whole list get updated everytime some value changes.
	 */
	getRoutes(){
		let infoDmc	=	this.commons.getInfoDmc();	
		this.listRouteSubscription	=	this.afs.collection('dmcs')
												.doc(infoDmc.id)
												.collection('destinations')
												.doc(infoDmc.destination)
												.collection('drivers')
												.doc(this.commons.userInfo.driverId)
				.valueChanges().subscribe((data : any)=>{
				// this.routes.next([]);
				this.firebaseControler.subscribeMultiObservables(data.routes).subscribe(routes =>{
					routes.forEach(async (route : any) =>{
						console.log("Mountig route",route);
						await this.mountRoute(route);
					});
					this.routes.next(routes);
				})
		})
	}
	/**
	 * Promised version for getting the routes from a driver. 
	 */
	async getMyRoutes(){
		// let driverInfo	:	any =	await this.firebaseControler.getDriverInfo(this.commons.userInfo.driverId);
		let driverInfo	= this.commons.userInfo.driver;
		if(!driverInfo.routes){ return []; };
		let listRoutes		=	await Promise.all(driverInfo.routes.map(async route => {
			let data = await this.firebaseControler.getDataFromRef(route);
			return data;
		}));
		let mountListRoutes	=	await Promise.all(listRoutes.map(async route => {
			// return { ...await this.mountFakeRoute(route), ...route }
			return route;
		}));

		console.log('List routes', 			listRoutes		);
		console.log('List routes mounted', 	mountListRoutes	);
		
		return mountListRoutes;
	}

	unsubscribeListRoutes()	{	this.listRouteSubscription.unsubscribe();	}
	subscribeToRef(ref)		{	return this.afs.doc(ref).valueChanges();	}
	
	/**
	 * subscrite to routes
	 * 
	 * Need to discuss. What's better, 
	 * 	a) Have a property with the current route
	 * 	b) From routes array get the current 
	 */
	_getCurrentRoute(){
		this.commons.generateToast("ERROR", "_GET_CURRENT_ROUTE_DISABLED");
		// console.log('Getting current route');
		// let infoDmc	= this.commons.getInfoDmc();
		// this.afs.doc(this.commons.userInfo.driverInfo.currentRoute)				
		// 		.subscribe((data : any ) =>{
		// 			this.subscribeToRef(data.currentRoute)
		// 				.subscribe(async (route : any) =>{
		// 				if(route){
		// 					await this.mountRoute(route);
		// 					this.currentRoute.next(route);
		// 				}else{
		// 					this.currentRoute.next(null);
		// 				}
		// 			})
		// 		});
	}

	/**
	 * Need to discuss. What's better, 
	 * 	a) Have a property with the current route
	 * 	b) From routes array get the current 
	 */
	 subscribeToCurrentRoute(){
		console.log('Getting current route');
		let infoDmc	=	this.commons.getInfoDmc();
		this.afs.collection('dmcs')
				.doc(infoDmc.id)
				.collection('destinations')
				.doc(infoDmc.destination)
				.collection('drivers')
				.doc(this.commons.userInfo.driverId)
				.valueChanges()
				.subscribe((data : any ) =>{
					this.subscribeToRef(data.currentRoute)
						.subscribe(async (route : any) =>{
						if(route){
							await this.mountRoute(route);
							this.currentRoute.next(route);
						}else{
							this.currentRoute.next(null);
						}
					})
				});
	}

	/**
	 * get driver current route and mount
	 * 
	 */
	async getCurrentRoute(){
		let infoDmc			=	this.commons.getInfoDmc();
		let driverInfo		=	await this.afs.collection('dmcs').doc(infoDmc.id).collection('destinations').doc(infoDmc.destination).collection('drivers').doc('1').ref.get();
		let currentRoute	=	await this.firebaseControler.getDataFromRef(driverInfo.data().currentRoute);
		
		console.log('CURRENT ROUTE', currentRoute);
		if(currentRoute){
			await this.mountRoute(currentRoute);
		}
		console.log('ROUTE PROMISED', currentRoute);
		return currentRoute;
	}

	/**
	 * subscrite to routes
	 * @param route 
	 */
	async mountRoute(route){
		if(!route){
			console.log("ROUTE undefined");
			return false;
		}
		let bookingsFromRoute		=	await this.firebaseControler.getPromiseFromMultiObservables(route.bookings);
		let infoFromBookings		=	await this.routeCtrl.getLodgingFromBooking(bookingsFromRoute)
		let infoCustomers			=	await this.firebaseControler.getPromiseFromMultiObservables(
																		infoFromBookings.filter	((el 		: any) => el.customerRef		)
																						.map	((booking 	: any) => booking.customerRef	)
																	);
		
		console.log(infoCustomers);

		// route.driverInfo		=	await this.firebaseControler.getDataFromRef(route.driver);
		route.driverInfo			=	this.commons.userInfo.driver;
		// route.customers			=	this.routeCtrl.mountCustomers(infoCustomers);
		
		if(!route.customers){
			route.customers			=	this.routeCtrl._mountCustomers(infoCustomers,infoFromBookings);
		}
		
		if(!route.lodgings){
			route.lodgings			=	this.routeCtrl.mountLodgings(route.customers);
		}
		// console.log('mounted test', test);
		return true;
	}
	
	/**
	 * mount testing route
	 * @param route 
	 * @returns 
	 */
	async mountFakeRoute(route){
		if(!route){ return []; }
		let infoBookings		=	await this.firebaseControler.getPromiseFromMultiObservables(route.bookings);
		let infoFromBookings	=	await this.routeCtrl.getLodgingFromBooking(infoBookings)

		let infoCustomers		=	await this.firebaseControler.getPromiseFromMultiObservables(infoFromBookings.filter((el : any) => el.customerRef).map((booking : any) => booking.customerRef))
		console.log(infoCustomers, infoFromBookings);
		let customers			=	route.customers	?	route.customers : this.routeCtrl._mountCustomers(infoCustomers,infoFromBookings);
		console.log('CUSTOMERS ARE', customers);
		return {
			customers	:	customers,
			lodgings	:	route.lodgings		?	route.lodgings		:			this.routeCtrl.mountLodgings(customers),
			driverInfo	:	route.driverInfo 	? 	route.driverInfo 	: 	await 	this.firebaseControler.getDataFromRef(route.driver),
		}
	}

	async restartRouteBookings(bookings){
		bookings = bookings || [];
		await Promise.all(bookings.map(async booking => await this.afs.doc(booking).update({customer_status	:	'_ARRIVAL_WAITING_TRANSPORT'})));
	}

	/** TODO METHODS FAKE NOW */
	async reqLocation(refBooking, refRoute){
		let bookingInfo			=	await this.firebaseControler.getDataFromRef(refBooking);
		let routeInfo			=	await this.firebaseControler.getDataFromRef(refRoute);
		console.log('going to ask location');
		routeInfo.historical	=	routeInfo.historical || [];
		let bodyRequest			=	{
			bookingId	:	bookingInfo.reference,
			status		:	'pending',
			type		:	'location',
			time		:	new Date()
		}
		// if(!bookingInfo.driverRequests){
		// 	bookingInfo.driverRequests = [bodyRequest]
		// }else{
		// 	let alreadyAsked	=	bookingInfo.driverRequests.find(el => (el.type == 'location') && (el.status == 'pending'));
		// 	if(alreadyAsked){	return {success : false, reason	:	'_ALREADY_ASKED'};	}
			
		// 	bookingInfo.driverRequests.push(bodyRequest);
		// }
		bookingInfo.driverRequests = [bodyRequest];
		routeInfo.historical.push(bodyRequest);

		console.log(bookingInfo.driverRequests);
		console.log(routeInfo.historical);

		await this.firebaseControler.updateItemByRef(refBooking,	{driverRequests	:	bookingInfo.driverRequests});
		await this.firebaseControler.updateItemByRef(refRoute, 	{historical		:	routeInfo.historical})
		
		return { success	:	true	}
	}

}
