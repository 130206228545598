import { 	Component, 
			OnInit, 
			Output,
			EventEmitter, 
			Input, 
			ChangeDetectionStrategy  	} from '@angular/core';
import { 	CommonsProvider 			} from 'src/app/providers/commons/commons';

@Component({
  changeDetection	: ChangeDetectionStrategy.OnPush,
  selector			: 'app-calendar',
  templateUrl		: 'app-calendar.html'
})
export class AppCalendarComponent implements OnInit {

	@Input()	currentDate	:	Date = new Date();
	@Output()	emitter		=	new EventEmitter();

	eventSource;
	isToday 	:	boolean;
    calendar = {
        mode		: 'month',
        currentDate	: new Date(),
	};
	viewTitle;
	pageInfo 	: any = {};	

	constructor(private commons	:	CommonsProvider) {
	}

	ngOnInit(){
		this.pageInfo = {
			calendar : {}
		}
	}
	onViewTitleChanged(title) {
        this.viewTitle = title;
    }

    onEventSelected(event) {
        console.log('Event selected:' + event.startTime + '-' + event.endTime + ',' + event.title);
    }

    changeMode(mode) {
        this.calendar.mode = mode;
    }

    today() {
		this.calendar.currentDate = new Date();
		
    }

    onTimeSelected(ev) {
		console.log(ev);
		this.emitter.emit({type : 'daySelected', value : ev.selectedTime});
        // console.log('Selected time: ' + ev.selectedTime + ', hasEvents: ' +
        //     (ev.events !== undefined && ev.events.length !== 0) + ', disabled: ' + ev.disabled);
    }

    onCurrentDateChanged(event:Date) {
		// console.log('DATE CHANGED', event);
		// this.pageInfo.calendar.currentMonth	=	this.commons.formatCustomDate(event,('MMMM'));
		this.emitter.emit({type : 'changedMonth', value : event	});
        var today = new Date();
        today.setHours(0, 0, 0, 0);
        event.setHours(0, 0, 0, 0);
        this.isToday = today.getTime() === event.getTime();
    }

	
    markDisabled = (date:Date) => {
        var current = new Date();
        current.setHours(0, 0, 0);
        return date < current;
	};

	next() {
		var swiper = document.querySelector('.swiper-container')['swiper'];
		swiper.slideNext();
	} 
	   
	back() {
		var swiper = document.querySelector('.swiper-container')['swiper'];
		swiper.slidePrev();
	}
	getCustomClass($event){
		// console.log($event);
		if($event.current){ 	return 'currentDay'		};
		if($event.selected){	return 'selectedDay'	};
		// if(this.markDisabled($event.date)){ return 'pastDay'};
		return;
	}

}
