import { Injectable 		} from '@angular/core';
import { items, treeItems 	} from './data/items';
/*
  Generated class for the TypedChatProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class TypedChatProvider {

	pageInfo	:	any	=	{}
	constructor() {
		this.initData();
	}

	initData(){
		this.pageInfo	=	{
			gifs	:	{
				'loading'	:  	'src/assets/imgs/loading.gif'
			}
		} 
	}    
	getTreeItem	(id)	{	return treeItems[id] ? treeItems[id] : []	};
	getItem		(id)	{	return items[id]		};

	mountLevel(idTreeItem){
		return {
			...JSON.parse(JSON.stringify(this.getTreeItem(idTreeItem))),
			items	:	treeItems[idTreeItem] ? JSON.parse(JSON.stringify(treeItems[idTreeItem].items.map(idItem => items[idItem]))) : []
		}
	}
 
	getGif(gif){
		console.log(this.pageInfo.gifs[gif]);
		return this.pageInfo.gifs[gif];
	}
} 
 