import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/*
	Generated class for the ValidatorProvider provider.

	See https://angular.io/guide/dependency-injection for more info on providers
	and Angular DI.
*/
@Injectable()
export class ValidatorProvider {

	static paths : any = {
		string : /\w+/
	}

	constructor(public http: HttpClient) {
		console.log('Hello ValidatorProvider Provider');
	}

	static isString(value, maxLenght?) {
		if (!value) return false;
		return this.paths.string.test(value) && maxLenght && value.lenght < maxLenght;
	}

	static isNumber(value) {
		if (!value) return false;
		value = Math.floor(Number(value.toString())); // force the value incase it is not
		return !isNaN(value) && value > 0;
	}

	static preResult(preResult) {
		return preResult;
	}

	static notVerify(){
		return true;
	}

	static emptyString(value) {
		return value != "";
	}

}
