import { RouteProvider } from 'src/app/providers/route/route';
import { Component, Input, Output, EventEmitter } from '@angular/core';

/**
 * Generated class for the CustomerItemComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector		: 'app-customer-item',
  templateUrl	: 'customer-item.html'
})
export class CustomerItemComponent {

	@Input() 	item			:	any = {};
	@Input()	slidingOptions	:	any;
	@Input()	actionStatus	:	any;
	@Output()	emiterOption	=	new EventEmitter();

  	constructor(private routeCtrl	:	RouteProvider) {
	  }

	mountPax(){
		if(!this.item || !this.item.pax){ return [];}
		return Object.keys(this.item.pax).map(el =>{
			return {
				type 	: 	el,
				qty		:	this.item.pax[el],
				img		:	this.getTypeIcon(el)
			}
		})
	}

	getTypeIcon(type)			{	return 'assets/imgs/'+type+'.png';	}
	
	selectSlidingOption(value)	{	
		this.emiterOption.emit({type	:	'slide',
								data	: {	item	:	this.item,	value	:	value	}					
								});
	}
	
	selectActionStatus()		{
		this.emiterOption.emit({type 	:	'actionStatus',
								data	: { item	:	this.item,	value	:	this.actionStatus.value ? this.actionStatus.value : null}})
	}

	clickRow(){
		this.emiterOption.emit({type	:	'clickRow',
								data	:	{ item	:	this.item,	value	:	this.actionStatus ? this.actionStatus.value : null}})
	}

	getDefaultAvatar(){
		return this.routeCtrl.getDefaultAvatar();
	}

	getStatusIcon(status){
		return this.routeCtrl.getStatusIcon(status);
	}
	getIconLocation(locationEnabled){
		return this.routeCtrl.getLocationIcon(locationEnabled);
	}
}
